/** API 商品詳細取得 */
const BASE_URL = "https://kneg8g4vwb.execute-api.ap-northeast-1.amazonaws.com/prod";
export const API_URL_PRODUCT_DETAIL = BASE_URL + "/product/detail";
/** API 商品検索 */
export const API_URL_SEARCH_PRODUCT = BASE_URL + "/product";
/** API メーカー検索 */
export const API_URL_SEARCH_MANUFACTURER = BASE_URL + "/manufacturer";
/** API 見積依頼 */
export const API_URL_QUOTATION = BASE_URL + "/quotation";
/** AmazonJPアイコンURL */
export const ICON_URL_AMAZON_JP = "https://multi-platform-data-public.s3.ap-northeast-1.amazonaws.com/image/AmazonJP.png"
/** AmazonUSアイコンURL */
export const ICON_URL_AMAZON_US = "https://multi-platform-data-public.s3.ap-northeast-1.amazonaws.com/image/AmazonUS.png"
/** shopifyアイコンURL */
export const ICON_URL_SHOPIFY = "https://multi-platform-data-public.s3.ap-northeast-1.amazonaws.com/image/Shopify.png"
/** RakutenアイコンURL */
export const ICON_URL_RAKUTEN = "https://multi-platform-data-public.s3.ap-northeast-1.amazonaws.com/image/Rakuten.png"
/** yahooアイコンURL */
export const ICON_URL_YAHOO = "https://multi-platform-data-public.s3.ap-northeast-1.amazonaws.com/image/yahoo.png"

