const isAllEmpty = (obj: { [key: string]: any }): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      //debug用
      console.log(key)
      console.log(typeof value)

      if (typeof value === "string") {
        if (value !== "") {
          //alert("stringあり");
          return false;
        }
      } else if (typeof value === "number") {
        if (value !== 0) {
          //alert("numberあり");
          return false;
        }
      } else {
        return true;
      }
    }
  }
  //alert("すべて空")
  return true;
};

/**
 * valueToCheckがallowedValuesのいずれかであることをチェックする
 * @param valueToCheck ：チェック対象の入力値
 * @param allowedValues ：許可する値のリスト(配列)
 * @returns 
 */
function checkValueInList(valueToCheck: string, allowedValues: string[]): boolean {
  return allowedValues.includes(valueToCheck);
}

/**
 * 
 * @param inputValue 
 * @param allowedValues 
 * @returns 
 */
function checkAllValuesInList(inputValue: string[], allowedValues: string[]): boolean {
  return inputValue.every(value => allowedValues.includes(value));
}


  /**
   * isPasswordValid
   * 以下の全ての条件に合致する場合はtrue、それ以外はfalseを返す
   * 10文字以上
   * 少なくとも 1 つの数字を含む
   * 少なくとも 1 つの特殊文字を含む
   * 特殊文字：^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =
   * 少なくとも 1 つの大文字を含む
   * 少なくとも 1 つの小文字を含む
   */
  const isPasswordValid = (password: string): boolean => {
    // 10文字以上
    if (password.length < 10) {
      return false;
    }
    // 少なくとも 1 つの数字を含む
    if (!/\d/.test(password)) {
      return false;
    }
    // 少なくとも 1 つの大文字を含む
    if (!/[A-Z]/.test(password)) {
      return false;
    }
    // 少なくとも 1 つの小文字を含む
    if (!/[a-z]/.test(password)) {
      return false;
    }
    // 少なくとも 1 つの特殊文字を含む
    //以下のURLを参照
    //https://qiita.com/nori4k/items/567c0e141ac8511543c5
    //http://www9.plala.or.jp/sgwr-t/c_sub/ascii.html
    if (!/[!-/:-@[-`{-~]/.test(password)) {
      return false;
    }

    return true;
  };

export { isAllEmpty, isPasswordValid, checkValueInList, checkAllValuesInList };
