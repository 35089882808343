import { Heading } from '@chakra-ui/react';
import React from 'react'

type Props = {
    children?: React.ReactNode;
  };

const Header:React.FC<Props> = ({children}) => {
  return (
    <Heading size={{ base: 'lg', md: 'xl' }} py="2">{children}</Heading>
  )
}

export default Header