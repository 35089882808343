import React, { memo, useState, ChangeEvent } from "react";
import {
  Box,
  Container,
  Input,
  Button,
  CircularProgress,
  Text,
  Grid,
  GridItem,
  Select,
  Flex,
  Center,
  Spacer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Checkbox,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link as ChakraLink,
  Tooltip,
  ButtonGroup,
  useToast,
} from "@chakra-ui/react";
import { ExternalLinkIcon, WarningIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import { useAuth } from "../hooks/use-auth";
import Navbar from "./molecules/Navbar";
//import getAPI from "../api/getAPI";
import axios from "axios";
import searchProductInputCommon from "../interface/searchProductInputCommon";

import { checkValueInList, checkAllValuesInList } from "../checker/checker";

import searchManufacturerInput from "../interface/searchManufacturerInput";
import searchManufacturerAPIParams from "../interface/searchManufacturerAPIParams";
import "../theme/style.css";
import {
  API_URL_SEARCH_PRODUCT,
  API_URL_SEARCH_MANUFACTURER,
} from "../constants/constants";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import SearchResultRow from "./molecules/SearchResultRow";
import {removeCommas, formatNumberWithComma } from "../utils/utils";

const SearchProduct: React.FC = memo(() => {
  const navigate = useNavigate();
  const toast = useToast();
  const ITEM_PER_PAGE = 100;
  const [totalPages, setTotalPages] = useState<number>(0);

  const PROD_CATEGORY_LIST: string[] = [
    "ホーム＆キッチン",
    "ベビー＆マタニティ",
    "スポーツ＆アウトドア",
    "ホビー",
    "おもちゃ",
    "ファッション",
    "文房具・オフィス用品",
    "アパレル",
    "ドラッグストア",
    "家電＆カメラ",
    "車＆バイク",
    "ビューティー",
    "本",
    "産業・研究開発用品",
    "DIY・工具・ガーデン",
    "パソコン・周辺機器",
    "大型家電",
    "食品・飲料・お酒",
    "ペット用品",
  ];

  /** メーカー検索時 */
  const [
    progressbarFlgSerachManufacturer,
    setProgressbarFlgSerachManufacturer,
  ] = useState<boolean>(false);
  const [messageSerachManufacturer, setMessageSerachManufacturer] =
    useState<string>("");
  const [buttonManufacturerText, setButtonManufacturerText] =
    useState("メーカー検索");

  /** 商品検索時 */
  const [messageSerachProduct, setMessageSerachProduct] = useState<string>("");
  const auth = useAuth();

  //＜＜メーカー検索基本条件＞＞
  // 国内出品可否
  const [prodDomesticAdmissibility, setProdDomesticAdmissibility] =
    useState<string>("0");
  // 海外出品可否
  const [prodOverseasAdmissibility, setProdOverseasAdmissibility] =
    useState<string>("0");
  // 国内ドロップシッピング可否
  const [prodDomesticDropShiiping, setProdDomesticDropShiiping] =
    useState<string>("0");
  // 海外ドロップシッピング可否
  const [prodOverseasDropShiiping, setProdOverseasDropShiiping] =
    useState<string>("0");
  // 仕入れ形態
  const [prodStockingPattern, setProdStockingPattern] = useState<string[]>([]);
  // 送料無料条件
  const [prodFreeShippingCondition, setProdFreeShippingCondition] =
    useState<string>("");
  //メーカー名(メーカー検索用の入力項目)
  const [prodManufacturerInput, setProdManufacturerInput] =
    useState<string>("");

  /**
   * メーカー検索結果のメーカーを選択することで作成されるメーカーリスト
   */
  const [prodSelectedManufacturers, setProdSelectedManufacturers] = useState<
    string[]
  >([]);

  //＜＜詳細検索条件＞＞
  // ステータス
  const [prodStatus, setProdStatus] = useState<string[]>([]);
  // ジャンル・カテゴリ
  const [prodCategory, setProdCategory] = useState<string>("");
  // 商品画像
  const [prodPictureUm, setProdPictureUm] = useState<string>("0");
  // 購入制限数 単位
  const [prodPurchaseLimit, setProdPurchaseLimit] = useState<string>("");
  // 仕入単価(From)
  const [prodPurchasePriceFrom, setProdPurchasePriceFrom] =
    useState<string>("");
  // 仕入単価(To)
  const [prodPurchasePriceTo, setProdPurchasePriceTo] = useState<string>("");
  // 定価(From)
  const [prodRegularPriceFrom, setProdRegularPriceFrom] = useState<string>("");
  // 定価(To)
  const [prodRegularPriceTo, setProdRegularPriceTo] = useState<string>("");
  // amazon.jpランキング(From)
  const [prodJpRankingFrom, setProdJpRankingFrom] = useState<string>("");
  // amazon.jpランキング(To)
  const [prodJpRankingTo, setProdJpRankingTo] = useState<string>("");
  // アマゾンJP単価(From)
  const [prodJpPriceFrom, setProdJpPriceFrom] = useState<string>("");
  // アマゾンJP単価(To)
  const [prodJpPriceTo, setProdJpPriceTo] = useState<string>("");
  // amazon.comランキング(From)
  const [prodComRankingFrom, setProdComRankingFrom] = useState<string>("");
  // amazon.comランキング(To)
  const [prodComRankingTo, setProdComRankingTo] = useState<string>("");
  // amazon.com単価(From)
  const [prodComPriceFrom, setProdComPriceFrom] = useState<string>("");
  // amazon.com単価(To)
  const [prodComPriceTo, setProdComPriceTo] = useState<string>("");

  //並び順(メーカー検索)
  const [prodOrderCd1, setProdOrderCd1] = useState<string>("1");

  //＜＜商品検索＞＞
  // 商品名
  const [prodProductName, setProdProductName] = useState<string>("");
  // JANコード
  const [prodJanCode, setProdJanCode] = useState<string>("");
  // ASIN
  const [prodAsin, setProdAsin] = useState<string>("");

  //並び順(商品検索)
  const [prodOrderCd2, setProdOrderCd2] = useState<string>("1");

  /** ハンドラ */
  //ステータスのチェック時
  const handleProdStatusChange = (value: string) => {
    if (prodStatus.includes(value)) {
      // チェックが外れた場合、valueを削除
      setProdStatus(prodStatus.filter((val) => val !== value));
    } else {
      // チェックされた場合、valueを追加
      setProdStatus([...prodStatus, value]);
    }
  };

  /** 仕入れ形態のチェック時  */
  const handleStockingPatternChange = (value: string) => {
    if (prodStockingPattern.includes(value)) {
      // チェックが外れた場合、valueを削除
      setProdStockingPattern(
        prodStockingPattern.filter((val) => val !== value)
      );
    } else {
      // チェックされた場合、valueを追加
      setProdStockingPattern([...prodStockingPattern, value]);
    }
  };

  /** ページング処理用
   * 検索後、2ページ目以降の検索ができるように、検索した際の入力値を保存する
   */
  const [previousSearchAPIParams, setPreviousSearchAPIParams] =
    useState<searchProductInputCommon>({
      prodCategory: "",
      prodPictureUm: "",
      prodPurchaseLimit: "",
      prodPurchasePriceFrom: "",
      prodPurchasePriceTo: "",
      prodRegularPriceFrom: "",
      prodRegularPriceTo: "",
      prodJpRankingFrom: "",
      prodJpRankingTo: "",
      prodJpPriceFrom: "",
      prodJpPriceTo: "",
      prodComRankingFrom: "",
      prodComRankingTo: "",
      prodComPriceFrom: "",
      prodComPriceTo: "",
      prodProductName: "",
      prodJanCode: "",
      prodAsin: "",
      prodOrderCd: "",
      prodSelectedManufacturers: "",
      prodStatus: [],
      prodPageNum: 1,
      prodSearchLabel: "Manufacturer",
    });

  //商品検索エラー時のエラー
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [progressbarFlg, setProgressbarFlg] = useState<boolean>(false);
  //検索結果
  const [searchResult, setSearchResult] = useState<string[][]>([[]]);
  const [searchResultCount, setSearchResultCount] = useState<number>(0);

  /** css */
  const cssOneline: React.CSSProperties = {
    whiteSpace: "nowrap",
  };
  /** css */

  // 現在のページ番号を保持する状態
  const [currentPage, setCurrentPage] = useState<number>(1);

  /** メーカー検索ボタン押下時 */
  const onClickManufacturerSearch = async () => {
    //サーバーからユーザーの現時点の認証情報を取得
    await auth.currentAuthenticatedUser();
    //認証切れの場合はログイン画面へ遷移
    if (!auth.isAuthenticated) {
      toast({
        title: "タイムアウトしました。",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      navigate({ pathname: "/" });
      return;
    }
    setProgressbarFlgSerachManufacturer(true);
    setButtonManufacturerText("　検索中　　");
    setMessageSerachManufacturer("");
    console.log("onClickManufacturerSearch");
    const inputParams: searchManufacturerInput = {
      prodDomesticAdmissibility: prodDomesticAdmissibility,
      prodOverseasAdmissibility: prodOverseasAdmissibility,
      prodDomesticDropShiiping: prodDomesticDropShiiping,
      prodOverseasDropShiiping: prodOverseasDropShiiping,
      prodStockingPattern: prodStockingPattern,
      prodFreeShippingCondition: prodFreeShippingCondition,
      prodManufacturerInput: prodManufacturerInput,
    };
    //checkInputManufacturerSearch(inputParams);
    const checkMessage = checkInputManufacturerSearch(inputParams);
    if (checkMessage !== "") {
      setMessageSerachManufacturer(checkMessage);
      setProgressbarFlgSerachManufacturer(false);
      return;
    }
    //API呼び出し
    const searchManufacturerApiParams: searchManufacturerAPIParams = {
      prodDomesticAdmissibility: prodDomesticAdmissibility,
      prodOverseasAdmissibility: prodOverseasAdmissibility,
      prodDomesticDropShiiping: prodDomesticDropShiiping,
      prodOverseasDropShiiping: prodOverseasDropShiiping,
      prodStockingPattern: prodStockingPattern.join(","),
      prodFreeShippingCondition: prodFreeShippingCondition,
      prodManufacturerInput: prodManufacturerInput,
    };
    try {
      const response = await axios.get(API_URL_SEARCH_MANUFACTURER, {
        params: searchManufacturerApiParams,
        headers: {
          Authorization: auth.token,
        },
      });
      console.log(response);
      const errorMessage = response.data.error;
      if (errorMessage !== "") {
        setMessageSerachManufacturer(errorMessage);
      } else {
        setProdManufacturerJsonData(response.data.search_result);
        //setMessageSerachManufacturer("検索結果：" + response.data.search_result.length + "　件");
      }
      //setSearchResult(response.data.search_result);
    } catch (error) {
      console.error(error);
      setMessageSerachManufacturer("エラーが発生しました。");
    } finally {
      setProgressbarFlgSerachManufacturer(false);
      setButtonManufacturerText("　検索完了　");
      setTimeout(() => {
        setButtonManufacturerText("メーカー検索");
      }, 2000);
    }
  };

  const checkInputManufacturerSearch = (
    inputParams: searchManufacturerInput
  ) => {
    console.log(inputParams);
    // if (isAllEmpty(inputParams)) {
    //   return "入力が不正です";
    // }
    if (!checkValueInList(inputParams.prodDomesticAdmissibility, ["0", "1"])) {
      return "国内EC2販売の入力が不正です";
    }
    if (!checkValueInList(inputParams.prodOverseasAdmissibility, ["0", "1"])) {
      return "海外EC2販売の入力が不正です";
    }
    if (!checkValueInList(inputParams.prodDomesticDropShiiping, ["0", "1"])) {
      return "国内ﾄﾞﾛｯﾌﾟｼｯﾌﾟの入力が不正です";
    }
    if (!checkValueInList(inputParams.prodOverseasDropShiiping, ["0", "1"])) {
      return "海外ﾄﾞﾛｯﾌﾟｼｯﾌﾟの入力が不正です";
    }
    if (
      !checkAllValuesInList(inputParams.prodStockingPattern, [
        "",
        "1",
        "2",
        "3",
      ])
    ) {
      return "仕入れ形態の入力が不正です";
    }
    if (
      !checkValueInList(inputParams.prodFreeShippingCondition, [
        "",
        "1",
        "2",
        "3",
      ])
    ) {
      return "送料無料条件の入力が不正です";
    }
    //全ての項目に入力が無い場合はエラー
    if (
      inputParams.prodDomesticAdmissibility === "0" &&
      inputParams.prodOverseasAdmissibility === "0" &&
      inputParams.prodDomesticDropShiiping === "0" &&
      inputParams.prodOverseasDropShiiping === "0" &&
      inputParams.prodStockingPattern.length === 0 &&
      inputParams.prodFreeShippingCondition === "" &&
      inputParams.prodManufacturerInput === ""
    ) {
      return "次のいずれかの項目に入力してください。国内EC2販売、海外EC2販売、国内ﾄﾞﾛｯﾌﾟｼｯﾌﾟ、海外ﾄﾞﾛｯﾌﾟｼｯﾌﾟ、仕入れ形態、購入制限数単位、送料無料条件、メーカー名";
    }
    return "";
  };

  const checkInputProductSearchFromManufacturer = (
    inputParams: searchProductInputCommon
  ) => {
    console.log(inputParams);

    //prodSelectedManufacturers が1つ以上であること
    if (inputParams.prodSelectedManufacturers.length === 0) {
      return "メーカーが選択されていません";
    }

    //prodStatus:各々の選択した値が全て"","1","2","9"のいずれかであること
    if (!checkAllValuesInList(inputParams.prodStatus, ["", "1", "2", "9"])) {
      return "商品ステータスの入力が不正です";
    }

    //商品カテゴリーのチェック
    //prodCategoryが空でない場合は、リストの中にデータが含まれること
    if (
      inputParams.prodCategory !== "" &&
      !checkValueInList(inputParams.prodCategory, PROD_CATEGORY_LIST)
    ) {
      return "商品カテゴリーの入力が不正です";
    }

    //prodPictureUm：0,1のいずれかであること
    if (!checkValueInList(inputParams.prodPictureUm, ["0", "1"])) {
      return "商品画像有無の入力が不正です";
    }

    // if (inputParams.prodProductName === "") {
    //   return "商品名の入力が不正です";
    // }
    //購入制限数単位のチェック
    //入力がある場合は7桁までの数字であること
    if (
      inputParams.prodPurchaseLimit !== "" &&
      !/^[1-9]\d{0,6}$/.test(inputParams.prodPurchaseLimit)
    ) {
      return "購入制限数単位の入力が不正です。7桁までの数字で入力してください";
    }

    //仕入単価のチェック
    //入力がある場合は7桁までの数字であること
    if (
      inputParams.prodPurchasePriceFrom !== "" &&
      !/^[1-9]\d{0,6}$/.test(inputParams.prodPurchasePriceFrom)
    ) {
      return "仕入単価の入力が不正です。7桁までの数字で入力してください";
    }
    if (
      inputParams.prodPurchasePriceTo !== "" &&
      !/^[1-9]\d{0,6}$/.test(inputParams.prodPurchasePriceTo)
    ) {
      return "仕入単価の入力が不正です。7桁までの数字で入力してください";
    }
    //定価のチェック
    //入力がある場合は7桁までの数字であること
    if (
      inputParams.prodRegularPriceFrom !== "" &&
      !/^[1-9]\d{0,6}$/.test(inputParams.prodRegularPriceFrom)
    ) {
      return "定価の入力が不正です。7桁までの数字で入力してください";
    }
    if (
      inputParams.prodRegularPriceTo !== "" &&
      !/^[1-9]\d{0,6}$/.test(inputParams.prodRegularPriceTo)
    ) {
      return "定価の入力が不正です。7桁までの数字で入力してください";
    }

    //amazonJPランキング
    //入力がある場合は8桁までの数字であること
    if (
      inputParams.prodJpRankingFrom !== "" &&
      !/^[1-9]\d{0,7}$/.test(inputParams.prodJpRankingFrom)
    ) {
      return "amazonJPランキングの入力が不正です。8桁までの数字で入力してください";
    }
    if (
      inputParams.prodJpRankingTo !== "" &&
      !/^[1-9]\d{0,7}$/.test(inputParams.prodJpRankingTo)
    ) {
      return "amazonJPランキングの入力が不正です。8桁までの数字で入力してください";
    }

    //amazonJP単価
    //入力がある場合は7桁までの数字であること
    if (
      inputParams.prodJpPriceFrom !== "" &&
      !/^[1-9]\d{0,6}$/.test(inputParams.prodJpPriceFrom)
    ) {
      return "amazonJP単価の入力が不正です。7桁までの数字で入力してください";
    }
    if (
      inputParams.prodJpPriceTo !== "" &&
      !/^[1-9]\d{0,6}$/.test(inputParams.prodJpPriceTo)
    ) {
      return "amazonJP単価の入力が不正です。7桁までの数字で入力してください";
    }

    //amazoncomランキング
    //入力がある場合は8桁までの数字であること
    if (
      inputParams.prodComRankingFrom !== "" &&
      !/^[1-9]\d{0,7}$/.test(inputParams.prodComRankingFrom)
    ) {
      return "amazoncomランキングの入力が不正です。8桁までの数字で入力してください";
    }
    if (
      inputParams.prodComRankingTo !== "" &&
      !/^[1-9]\d{0,7}$/.test(inputParams.prodComRankingTo)
    ) {
      return "amazoncomランキングの入力が不正です。8桁までの数字で入力してください";
    }

    //amazoncom単価
    //入力がある場合は整数7桁、小数2桁までの数字であること
    if (
      inputParams.prodComPriceFrom !== "" &&
      !/^[1-9]\d{0,6}(\.\d{1,2})?$/.test(inputParams.prodComPriceFrom)
    ) {
      return "amazoncom単価の入力が不正です。整数7桁、小数2桁までの数字で入力してください";
    }
    if (
      inputParams.prodComPriceTo !== "" &&
      !/^[1-9]\d{0,6}(\.\d{1,2})?$/.test(inputParams.prodComPriceTo)
    ) {
      return "amazoncom単価の入力が不正です。整数7桁、小数2桁までの数字で入力してください";
    }

    //並び順
    //"1"~"7"のいずれかであること
    if (
      !checkValueInList(inputParams.prodOrderCd, [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
      ])
    ) {
      return "並び順の入力が不正です。1~7の数字で入力してください";
    }

    return "";
  };

  const checkInputProductSearchFromProduct = (
    inputParams: searchProductInputCommon
  ) => {
    //1文字以上100文字以内であること、改行コードとカンマを含まないこと
    if (
      inputParams.prodProductName !== "" &&
      !/^.{1,100}$/.test(inputParams.prodProductName)
    ) {
      return "商品名の入力が不正です。1文字以上100文字以内で入力してください";
    }

    //JANコード
    //8桁または13桁の数字であること
    if (
      inputParams.prodJanCode !== "" &&
      !/^\d{8}$|^\d{13}$/.test(inputParams.prodJanCode)
    ) {
      return "JANコードの入力が不正です。8桁または13桁の数字で入力してください";
    }

    //ASIN
    //入力がある場合は10桁の英字大文字と数字であること
    if (
      inputParams.prodAsin !== "" &&
      !/^[A-Z0-9]{10}$/.test(inputParams.prodAsin)
    ) {
      return "ASINの入力が不正です。10桁の英字大文字と数字で入力してください";
    }

    //並び順
    //"1"~"7"のいずれかであること
    if (
      !checkValueInList(inputParams.prodOrderCd, [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
      ])
    ) {
      return "並び順の入力が不正です。1~7の数字で入力してください";
    }

    return "";
  };

  /**
   * 商品検索(メーカー検索から押下)
   */
  const onClickSearch1 = async () => {
    setCurrentPage(1);
    onClickSearch("Manufacturer", "searchButton");
  };

  /**
   * 商品検索(商品検索から押下)
   */
  const onClickSearch2 = async () => {
    setCurrentPage(1);
    onClickSearch("Product", "searchButton");
  };

  const continueSearch = async (pageNum: number) => {
    setCurrentPage(pageNum);
    onClickSearch(undefined, "pageButton");
  };

  // ページネーションコンポーネント
  const Pagination = () => (
    <Flex justify="center" align="center" my="4">
      <ButtonGroup isAttached variant="outline">
        {totalPages >= 6 && currentPage >= 4 && (
          <Button onClick={() => continueSearch(1)}>最初へ</Button>
        )}
        {currentPage !== 1 && (
          <Button
            onClick={() => continueSearch(currentPage - 1)}
            isDisabled={currentPage === 1}
          >
            前へ
          </Button>
        )}
        {totalPages >= 6 && currentPage - 3 >= 1 && <Text mt="2">・・・</Text>}
        {/* ページ番号ボタンを動的に生成 */}
        {Array.from({ length: Math.min(5, totalPages) }, (_, index) => {
          let pageNumber: number = 1;
          if (currentPage < 3) {
            pageNumber = 1 + index;
          } else if (currentPage >= totalPages - 1) {
            pageNumber = totalPages - 3 + index; // ここを変更
          } else {
            pageNumber = currentPage - 2 + index;
          }
          return (
            pageNumber > 0 &&
            pageNumber <= totalPages && (
              <Button
                key={pageNumber}
                onClick={() => continueSearch(pageNumber)}
                isActive={currentPage === pageNumber}
                isDisabled={currentPage === pageNumber} // 現在のページは押せないようにする
                _disabled={{
                  bg: "blue.500", // 無効でも背景色を青色にする
                  color: "white", // 無効でもテキストを白色にする
                  cursor: "not-allowed",
                }}
              >
                {pageNumber}
              </Button>
            )
          );
        })}

        {totalPages >= 6 && currentPage <= totalPages - 3 && (
          <Text mt="2">・・・</Text>
        )}
        {currentPage !== totalPages && (
          <Button
            onClick={() => continueSearch(currentPage + 1)}
            isDisabled={currentPage === totalPages}
          >
            次へ
          </Button>
        )}
        {totalPages >= 6 && currentPage <= totalPages - 3 && (
          <Button onClick={() => continueSearch(totalPages)}>最後へ</Button>
        )}
      </ButtonGroup>
    </Flex>
  );

  const PaginationInfo = () => {
    // 開始アイテム番号の計算
    const startItem = (currentPage - 1) * ITEM_PER_PAGE + 1;

    // 終了アイテム番号の計算
    const endItem = Math.min(
      startItem + searchResult.length - 1,
      searchResultCount
    );

    return (
      <div>
        検索結果：{searchResultCount}件中　{startItem}件 〜 {endItem}件　
        {currentPage}ページ目を表示
      </div>
    );
  };
  /**
   *
   * @returns 商品検索(メイン)
   */
  const onClickSearch = async (
    label: string = "Manufacturer",
    actionButton: string = ""
  ) => {
    //認証チェック
    await auth.currentAuthenticatedUser();
    if (!auth.isAuthenticated) {
      toast({
        title: "タイムアウトしました。",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      navigate({ pathname: "/" });
      return;
    }
    setProgressbarFlg(true);
    setErrorMessage("");

    //console.log(actionButton);

    const checkInputParams: searchProductInputCommon = {
      prodCategory:
        actionButton === "pageButton"
          ? previousSearchAPIParams.prodCategory
          : prodCategory,
      prodPictureUm:
        actionButton === "pageButton"
          ? previousSearchAPIParams.prodPictureUm
          : prodPictureUm,
      prodPurchaseLimit:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodPurchaseLimit)
          : removeCommas(prodPurchaseLimit),
      prodPurchasePriceFrom:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodPurchasePriceFrom)
          : removeCommas(prodPurchasePriceFrom),
      prodPurchasePriceTo:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodPurchasePriceTo)
          : removeCommas(prodPurchasePriceTo),
      prodRegularPriceFrom:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodRegularPriceFrom)
          : removeCommas(prodRegularPriceFrom),
      prodRegularPriceTo:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodRegularPriceTo)
          : removeCommas(prodRegularPriceTo),
      prodJpRankingFrom:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodJpRankingFrom)
          : removeCommas(prodJpRankingFrom),
      prodJpRankingTo:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodJpRankingTo)
          : removeCommas(prodJpRankingTo),
      prodJpPriceFrom:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodJpPriceFrom)
          : removeCommas(prodJpPriceFrom),
      prodJpPriceTo:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodJpPriceTo)
          : removeCommas(prodJpPriceTo),
      prodComRankingFrom:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodComRankingFrom)
          : removeCommas(prodComRankingFrom),
      prodComRankingTo:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodComRankingTo)
          : removeCommas(prodComRankingTo),
      prodComPriceFrom:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodComPriceFrom)
          : removeCommas(prodComPriceFrom),
      prodComPriceTo:
        actionButton === "pageButton"
          ? removeCommas(previousSearchAPIParams.prodComPriceTo)
          : removeCommas(prodComPriceTo),
      prodProductName:
        actionButton === "pageButton"
          ? previousSearchAPIParams.prodProductName
          : prodProductName,
      prodJanCode:
        actionButton === "pageButton"
          ? previousSearchAPIParams.prodJanCode
          : prodJanCode,
      prodAsin:
        actionButton === "pageButton"
          ? previousSearchAPIParams.prodAsin
          : prodAsin,
      prodOrderCd:
        actionButton === "pageButton"
          ? previousSearchAPIParams.prodOrderCd
          : label === "Manufacturer"
          ? prodOrderCd1
          : prodOrderCd2,
      prodSelectedManufacturers:
        actionButton === "pageButton"
          ? previousSearchAPIParams.prodSelectedManufacturers
          : prodSelectedManufacturers.join(","),
      prodStatus:
        actionButton === "pageButton"
          ? previousSearchAPIParams.prodStatus
          : prodStatus,
      prodPageNum: currentPage,
      prodSearchLabel:
        actionButton === "pageButton"
          ? previousSearchAPIParams.prodSearchLabel
          : label,
    };

    //チェック
    if (label === "Manufacturer") {
      const errorMessage = checkInputProductSearchFromManufacturer(checkInputParams);
      if (errorMessage !== "") {
        setErrorMessage(errorMessage);
        setProgressbarFlg(false);
        return;
      }
    } else if (label === "Product") {
      const errorMessage = checkInputProductSearchFromProduct(checkInputParams);
      if (errorMessage !== "") {
        setErrorMessage(errorMessage);
        setProgressbarFlg(false);
        return;
      }
    }

    const apiParams = {
      ...checkInputParams,
      prodStatus: checkInputParams.prodStatus.join(","),
    };

    console.log(apiParams);
    try {
      const response = await axios.get(API_URL_SEARCH_PRODUCT, {
        params: apiParams,
        headers: {
          Authorization: auth.token,
        },
      });
      console.log(response);
      // console.log(response.data.search_result);
      //検索時の情報を退避
      setPreviousSearchAPIParams(checkInputParams);
      setSearchResult(response.data.search_result);
      setSearchResultCount(response.data.result_count);
      setTotalPages(Math.ceil(response.data.result_count / ITEM_PER_PAGE));
      if (response.data.error !== "") {
        console.log(response.data.error);
        setErrorMessage(response.data.error);
      } else if (response.data.result_count === 0) {
        setErrorMessage("検索に該当する商品がありませんでした。");
        setSearchResult([]);
        setSearchResultCount(0);
        setTotalPages(0);
      }
      console.log(searchResult);
      //console.log("totalPages:" + totalPages);
    } catch (error) {
      console.error(error);
      setErrorMessage("エラーが発生しました。");
      setSearchResult([]);
      setSearchResultCount(0);
      setTotalPages(0);
    } finally {
      setProgressbarFlg(false);
    }
  };

  /**
   * prodManufacturerJsonData
   *
   */
  const [prodManufacturerJsonData, setProdManufacturerJsonData] = useState<
    string[] | undefined
  >(undefined);

  /**
   * メーカーの追加
   * @param e
   */
  const handleOptionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    console.log(selectedValue);
    // setProdSelectedManufacturer(selectedValue);
    setProdSelectedManufacturers((prevSelectedOptions) => {
      // 既に含まれているかチェック
      if (prevSelectedOptions.includes(selectedValue)) {
        return prevSelectedOptions;
      }

      //placeholderを選択した場合に空("")を追加しないための考慮
      if (selectedValue === "") {
        return prevSelectedOptions;
      }

      // 含まれていない場合、追加して返す
      return [...prevSelectedOptions, selectedValue];
    });
  };

  /**
   * 選択したメーカーの削除
   * @param valueToRemove
   */
  const handleRemoveOption = (valueToRemove: string) => {
    setProdSelectedManufacturers((prevSelectedOptions) => {
      const updatedOptions = prevSelectedOptions.filter(
        (option) => option !== valueToRemove
      );
      return updatedOptions;
    });
  };

  return (
    <>
      <Navbar />
      {/* <Header>商品検索</Header> */}
      <Container
        pt={{ base: "4", md: "8" }}
        pb={{ base: "12", md: "20" }}
        px={{ base: "0", md: "0" }}
        maxW={{ base: "100%", md: "80%" }}
      >
        <Box
          //as="form"
          shadow="md"
          borderRadius="md"
          bg={{ base: "white", md: "bg-surface" }}
          //m="2"
          py={{ base: "0", md: "2" }}
          px={{ base: "4", md: "2" }}
        >
          {/* <Flex justify="center" align="center">
            <Header>商品検索</Header>
          </Flex> */}

          <Tabs isFitted variant="solid-rounded" colorScheme="blue">
            <TabList mb="1em">
              <Tab>メーカー検索</Tab>
              <Tab>商品検索</Tab>
            </TabList>
            <TabPanels>
              {/* メーカー検索 */}
              <TabPanel>
                <Grid
                  minH="350px"
                  //w="700px"
                  py="4"
                  templateRows="repeat(5, 1fr)"
                  templateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {/** 国内EC2販売 */}
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Text fontSize="20" w="100%">
                      国内EC2販売
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Checkbox
                      value="1"
                      fontSize="lg"
                      size="lg"
                      isChecked={prodDomesticAdmissibility === "1"}
                      onChange={() =>
                        setProdDomesticAdmissibility(
                          prodDomesticAdmissibility === "0" ? "1" : "0"
                        )
                      }
                    >
                      <Text fontSize="20" w="100%">
                        可&emsp;
                      </Text>
                    </Checkbox>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} bg="white"></GridItem>
                  {/** 海外EC2販売 */}
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Text fontSize="20" w="100%">
                      海外EC2販売
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Checkbox
                      value="1"
                      fontSize="lg"
                      size="lg"
                      isChecked={prodOverseasAdmissibility === "1"}
                      onChange={() =>
                        setProdOverseasAdmissibility(
                          prodOverseasAdmissibility === "0" ? "1" : "0"
                        )
                      }
                    >
                      <Text fontSize="20" w="100%">
                        可&emsp;
                      </Text>
                    </Checkbox>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} bg="white"></GridItem>
                  {/** 国内ﾄﾞﾛｯﾌﾟｼｯﾌ */}
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Text fontSize="20" w="100%">
                      国内ﾄﾞﾛｯﾌﾟｼｯﾌﾟ
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Checkbox
                      value="1"
                      fontSize="lg"
                      size="lg"
                      isChecked={prodDomesticDropShiiping === "1"}
                      onChange={() =>
                        setProdDomesticDropShiiping(
                          prodDomesticDropShiiping === "0" ? "1" : "0"
                        )
                      }
                    >
                      <Text fontSize="20" w="100%">
                        可&emsp;
                      </Text>
                    </Checkbox>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} bg="white"></GridItem>
                  {/** 海外ﾄﾞﾛｯﾌﾟｼｯﾌﾟ */}
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Text fontSize="20" w="100%">
                      海外ﾄﾞﾛｯﾌﾟｼｯﾌﾟ
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Checkbox
                      value="1"
                      fontSize="lg"
                      size="lg"
                      isChecked={prodOverseasDropShiiping === "1"}
                      onChange={() =>
                        setProdOverseasDropShiiping(
                          prodOverseasDropShiiping === "0" ? "1" : "0"
                        )
                      }
                    >
                      <Text fontSize="20" w="100%">
                        可&emsp;
                      </Text>
                    </Checkbox>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} bg="white"></GridItem>
                  {/** 仕入れ形態 */}
                  <GridItem rowSpan={1} colSpan={4} bg="glay.100">
                    <Text fontSize="20" w="100%">
                      仕入れ形態
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={8} bg="white">
                    <Checkbox
                      value="1"
                      fontSize="lg"
                      size="lg"
                      isChecked={prodStockingPattern.includes("1")}
                      onChange={() => handleStockingPatternChange("1")}
                    >
                      <Text fontSize="20" w="100%">
                        メーカー&emsp;
                      </Text>
                    </Checkbox>
                    <Checkbox
                      value="2"
                      fontSize="lg"
                      size="lg"
                      isChecked={prodStockingPattern.includes("2")}
                      onChange={() => handleStockingPatternChange("2")}
                    >
                      <Text fontSize="20" w="100%">
                        卸&emsp;
                      </Text>
                    </Checkbox>
                    <Checkbox
                      value="3"
                      fontSize="lg"
                      size="lg"
                      isChecked={prodStockingPattern.includes("3")}
                      onChange={() => handleStockingPatternChange("3")}
                    >
                      <Text fontSize="20" w="100%">
                        その他&emsp;
                      </Text>
                    </Checkbox>
                  </GridItem>
                  {/** 送料無料条件 */}
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Text fontSize="20" w="100%">
                      送料無料条件
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={8} bg="white">
                    <Select
                      placeholder="選択してください"
                      borderRadius="0.5rem"
                      borderColor="#BBBBBB"
                      borderWidth="2px"
                      value={prodFreeShippingCondition}
                      onChange={(e) =>
                        setProdFreeShippingCondition(e.target.value)
                      }
                    >
                      <option value="1">条件なし</option>
                      <option value="2">条件あり</option>
                      <option value="3">送料無料</option>
                    </Select>
                  </GridItem>

                  {/** メーカー名(メーカー検索用の入力項目) */}
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    {/* <Text fontSize="20" w="100%">
                      メーカー名
                    </Text> */}
                    <Box display="flex" alignItems="center">
                      <Box fontSize="20px" color="blue.600">
                        <ChakraLink
                          href="https://multi-platform-data-public.s3.ap-northeast-1.amazonaws.com/image/AmazonJP.png"
                          isExternal
                        >
                          メーカー名
                          <ExternalLinkIcon ml="4px" />
                        </ChakraLink>
                      </Box>
                    </Box>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={8} bg="white">
                    <Input
                      maxLength={8}
                      type="text"
                      fontSize="20"
                      w="100%"
                      size="sm"
                      borderRadius="0.5rem"
                      borderColor="#BBBBBB"
                      borderWidth="2px"
                      value={prodManufacturerInput}
                      onChange={(e) => setProdManufacturerInput(e.target.value)}
                    />
                  </GridItem>
                </Grid>
                <Flex
                  //minH="400px"
                  //w="700px"
                  //py="2"
                  justify="space-between" // コンテンツを左右に配置
                  align="center" // コンテンツを中央に配置
                >
                  {/* 検索エラーメッセージ */}
                  <Box flex="1">
                    {messageSerachManufacturer && (
                      <Box color="red" marginTop="10px">
                        {messageSerachManufacturer}
                      </Box>
                    )}
                  </Box>

                  {/* メーカー検索ボタン */}

                  <Box flex="1" textAlign="right">
                    <Button
                      isDisabled={progressbarFlgSerachManufacturer}
                      //isLoading={progressbarFlgSerachManufacturer}
                      bg="blue.500"
                      color="white"
                      leftIcon={
                        progressbarFlgSerachManufacturer ? (
                          <CircularProgress
                            isIndeterminate
                            size="30"
                            color="white"
                          />
                        ) : (
                          <SearchIcon m={1} />
                        )
                      }
                      _hover={{ bg: "blue.600" }}
                      onClick={onClickManufacturerSearch}
                    >
                      {buttonManufacturerText}
                    </Button>
                  </Box>
                </Flex>
                <Grid
                  minH="100px"
                  //w="700px"
                  py="4"
                  templateRows="repeat(1, 1fr)"
                  templateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {/* メーカー */}
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Text fontSize="20" w="100%">
                      メーカー
                    </Text>
                  </GridItem>

                  {/* 検索後のドロップダウンリスト */}
                  <GridItem rowSpan={1} colSpan={8} bg="white">
                    {/* <Select
                      placeholder="選択してください"
                      borderRadius="0.5rem"
                      borderColor="#BBBBBB"
                      borderWidth="2px"
                      disabled={prodManufacturerJsonData?.length == 0}
                      value={prodSelectedManufacturers}
                      onChange={handleOptionChange}
                    >
                      {prodManufacturerJsonData &&
                        prodManufacturerJsonData.map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                    </Select> */}
                    <Select
                      placeholder="選択してください"
                      borderRadius="0.5rem"
                      borderColor="#BBBBBB"
                      borderWidth="2px"
                      disabled={prodManufacturerJsonData?.length === 0}
                      value={
                        prodSelectedManufacturers.length > 0
                          ? prodSelectedManufacturers[0]
                          : ""
                      }
                      onChange={handleOptionChange}
                    >
                      {prodManufacturerJsonData &&
                        prodManufacturerJsonData.map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                    </Select>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} bg="white"></GridItem>
                  <GridItem rowSpan={1} colSpan={8} bg="white">
                    {prodSelectedManufacturers.length > 0 && (
                      <Box mt={2}>
                        {prodSelectedManufacturers.map((option, index) => (
                          <Flex key={index} align="center" py="1">
                            <Button
                              mr={2}
                              size="xs"
                              colorScheme="red"
                              onClick={() => handleRemoveOption(option)}
                            >
                              削除
                            </Button>
                            <Text>{option}</Text>
                          </Flex>
                        ))}
                      </Box>
                    )}
                  </GridItem>
                </Grid>

                <Accordion allowMultiple>
                  <AccordionItem border="blue.400">
                    {({ isExpanded }) => (
                      <>
                        <h2>
                          <AccordionButton
                            _focus={{ boxShadow: "none" }} // フォーカス時の影を無効にする
                            borderWidth={isExpanded ? "2px" : "1px"} // 境界線の太さを設定
                            borderColor="gray.300" // 境界線の色を設定
                            borderRadius="md" // 境界線の角を丸める
                            _hover={{ borderWidth: "2px" }} // ホバー時の境界線の太さ
                          >
                            {isExpanded ? (
                              <AiFillMinusCircle size={24} color="#4FD1C5" />
                            ) : (
                              <AiFillPlusCircle size={24} color="#4FD1C5" />
                            )}
                            <Box
                              as="span"
                              flex="1"
                              textAlign="left"
                              marginLeft="12px"
                            >
                              詳細検索
                            </Box>
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <Grid
                            h="480px"
                            templateRows="repeat(10, 1fr)"
                            templateColumns="repeat(12, 1fr)"
                            gap={2}
                          >
                            {/** ステータス */}
                            <GridItem rowSpan={1} colSpan={4} bg="white">
                              <Text fontSize="20" w="100%">
                                ステータス
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={8} bg="white">
                              <Checkbox
                                value="1"
                                fontSize="lg"
                                size="lg"
                                isChecked={prodStatus.includes("1")}
                                onChange={() => handleProdStatusChange("1")}
                              >
                                <Text fontSize="20" w="100%">
                                  在庫あり&emsp;
                                </Text>
                              </Checkbox>
                              <Checkbox
                                value="2"
                                fontSize="lg"
                                size="lg"
                                isChecked={prodStatus.includes("2")}
                                onChange={() => handleProdStatusChange("2")}
                              >
                                <Text fontSize="20" w="100%">
                                  欠品中&emsp;
                                </Text>
                              </Checkbox>
                              <Checkbox
                                value="3"
                                fontSize="lg"
                                size="lg"
                                isChecked={prodStatus.includes("9")}
                                onChange={() => handleProdStatusChange("9")}
                              >
                                <Text fontSize="20" w="100%">
                                  終売&emsp;
                                </Text>
                              </Checkbox>
                            </GridItem>
                            {/** ｼﾞｬﾝﾙ・ｶﾃｺﾞﾘ */}
                            <GridItem rowSpan={1} colSpan={4} bg="white">
                              <Text fontSize="20" w="100%">
                                ｼﾞｬﾝﾙ・ｶﾃｺﾞﾘ
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={8} bg="white">
                              <Select
                                placeholder="選択してください"
                                borderRadius="0.5rem"
                                borderColor="#BBBBBB"
                                borderWidth="2px"
                                value={prodCategory}
                                onChange={(e) =>
                                  setProdCategory(e.target.value)
                                }
                              >
                                {PROD_CATEGORY_LIST.map((janre) => (
                                  <option key={janre} value={janre}>
                                    {janre}
                                  </option>
                                ))}
                              </Select>
                            </GridItem>
                            {/** 商品画像 */}
                            <GridItem rowSpan={1} colSpan={4} bg="white">
                              <Text fontSize="20" w="100%">
                                商品画像有無
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={8} bg="white">
                              <Checkbox
                                value="1"
                                fontSize="lg"
                                size="lg"
                                isChecked={prodPictureUm === "1"}
                                onChange={() =>
                                  setProdPictureUm(
                                    prodPictureUm === "0" ? "1" : "0"
                                  )
                                }
                              >
                                <Text fontSize="20" w="100%">
                                  有&emsp;
                                </Text>
                              </Checkbox>
                            </GridItem>
                            {/** 購入制限数単位 */}
                            <GridItem rowSpan={1} colSpan={4} bg="white">
                              <Text fontSize="20" w="100%">
                                購入制限数単位
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3} bg="white">
                              <Input
                                maxLength={7}
                                type="text"
                                fontSize="20"
                                w="100%"
                                size="sm"
                                borderRadius="0.5rem"
                                borderColor="#BBBBBB"
                                borderWidth="2px"
                                value={prodPurchaseLimit}
                                // onChange={(e) =>
                                //   setProdPurchaseLimit(e.target.value)
                                // }
                                onChange={(e) =>
                                  setProdPurchaseLimit(e.target.value)
                                }
                                onBlur={(e) =>
                                  setProdPurchaseLimit(formatNumberWithComma(e.target.value))
                                }
                                onFocus={(e) =>
                                  setProdPurchaseLimit(removeCommas(e.target.value))
                                }
                              />
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={5} bg="white">
                              <Text fontSize="20" w="100%">
                                個以下
                              </Text>
                            </GridItem>
                            {/** 仕入単価 */}
                            <GridItem rowSpan={1} colSpan={4} bg="white">
                              <Text fontSize="20" w="100%">
                                仕入単価(円)
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="glay.100">
                                <Input
                                  maxLength={7}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodPurchasePriceFrom}
                                  onChange={(e) =>
                                    setProdPurchasePriceFrom(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdPurchasePriceFrom(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdPurchasePriceFrom(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>
                            <GridItem
                              rowSpan={1}
                              colSpan={2}
                              bg="white"
                              textAlign="center"
                            >
                              <Text fontSize="20" w="100%">
                                〜
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="white">
                                <Input
                                  maxLength={7}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodPurchasePriceTo}
                                  onChange={(e) =>
                                    setProdPurchasePriceTo(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdPurchasePriceTo(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdPurchasePriceTo(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>
                            {/** 定価 */}
                            <GridItem rowSpan={1} colSpan={4} bg="white">
                              <Text fontSize="20" w="100%">
                                定価(円)
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="glay.100">
                                <Input
                                  maxLength={7}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodRegularPriceFrom}
                                  onChange={(e) =>
                                    setProdRegularPriceFrom(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdRegularPriceFrom(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdRegularPriceFrom(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>
                            <GridItem
                              rowSpan={1}
                              colSpan={2}
                              bg="white"
                              textAlign="center"
                            >
                              <Text fontSize="20" w="100%">
                                〜
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="white">
                                <Input
                                  maxLength={7}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodRegularPriceTo}
                                  onChange={(e) =>
                                    setProdRegularPriceTo(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdRegularPriceTo(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdRegularPriceTo(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>

                            {/** Amazon.jp */}
                            <GridItem rowSpan={1} colSpan={12} bg="white">
                              <Text fontSize="20" w="100%">
                                amazon.jp
                              </Text>
                            </GridItem>
                            {/** amazon.jpﾗﾝｷﾝｸﾞ */}
                            <GridItem
                              rowSpan={1}
                              colSpan={1}
                              bg="white"
                            ></GridItem>
                            <GridItem rowSpan={1} colSpan={3} bg="white">
                              <Text fontSize="17" w="100%">
                                ランキング
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="glay.100">
                                <Input
                                  maxLength={8}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodJpRankingFrom}
                                  onChange={(e) =>
                                    setProdJpRankingFrom(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdJpRankingFrom(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdJpRankingFrom(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>
                            <GridItem
                              rowSpan={1}
                              colSpan={2}
                              bg="white"
                              textAlign="center"
                            >
                              <Text fontSize="20" w="100%">
                                〜
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="white">
                                <Input
                                  maxLength={8}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodJpRankingTo}
                                  onChange={(e) =>
                                    setProdJpRankingTo(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdJpRankingTo(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdJpRankingTo(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>

                            {/** amazon.jp単価 */}
                            <GridItem
                              rowSpan={1}
                              colSpan={1}
                              bg="white"
                            ></GridItem>
                            <GridItem rowSpan={1} colSpan={3} bg="white">
                              <Text fontSize="20" w="100%">
                                単価(円)
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="glay.100">
                                <Input
                                  maxLength={7}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodJpPriceFrom}
                                  onChange={(e) =>
                                    setProdJpPriceFrom(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdJpPriceFrom(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdJpPriceFrom(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>
                            <GridItem
                              rowSpan={1}
                              colSpan={2}
                              bg="white"
                              textAlign="center"
                            >
                              <Text fontSize="20" w="100%">
                                〜
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="white">
                                <Input
                                  maxLength={7}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodJpPriceTo}
                                  onChange={(e) =>
                                    setProdJpPriceTo(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdJpPriceTo(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdJpPriceTo(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>

                            {/** Amazon.com */}
                            <GridItem rowSpan={1} colSpan={12} bg="white">
                              <Text fontSize="20" w="100%">
                                amazon.com
                              </Text>
                            </GridItem>
                            {/** amazon.comﾗﾝｷﾝｸﾞ */}
                            <GridItem
                              rowSpan={1}
                              colSpan={1}
                              bg="white"
                            ></GridItem>
                            <GridItem rowSpan={1} colSpan={3} bg="white">
                              <Text fontSize="17" w="100%">
                                ランキング
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="glay.100">
                                <Input
                                  maxLength={8}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodComRankingFrom}
                                  onChange={(e) =>
                                    setProdComRankingFrom(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdComRankingFrom(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdComRankingFrom(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>
                            <GridItem
                              rowSpan={1}
                              colSpan={2}
                              bg="white"
                              textAlign="center"
                            >
                              <Text fontSize="20" w="100%">
                                〜
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="white">
                                <Input
                                  maxLength={8}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodComRankingTo}
                                  onChange={(e) =>
                                    setProdComRankingTo(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdComRankingTo(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdComRankingTo(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>
                            {/** amazon.com単価 */}
                            <GridItem
                              rowSpan={1}
                              colSpan={1}
                              bg="white"
                            ></GridItem>
                            <GridItem rowSpan={1} colSpan={3} bg="white">
                              <Text fontSize="20" w="100%">
                                単価($)
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="glay.100">
                                <Input
                                  maxLength={10}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodComPriceFrom}
                                  onChange={(e) =>
                                    setProdComPriceFrom(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdComPriceFrom(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdComPriceFrom(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>
                            <GridItem
                              rowSpan={1}
                              colSpan={2}
                              bg="white"
                              textAlign="center"
                            >
                              <Text fontSize="20" w="100%">
                                〜
                              </Text>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={3}>
                              <GridItem rowSpan={1} colSpan={9} bg="white">
                                <Input
                                  maxLength={10}
                                  type="text"
                                  fontSize="20"
                                  w="100%"
                                  size="sm"
                                  borderRadius="0.5rem"
                                  borderColor="#BBBBBB"
                                  borderWidth="2px"
                                  value={prodComPriceTo}
                                  onChange={(e) =>
                                    setProdComPriceTo(e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setProdComPriceTo(formatNumberWithComma(e.target.value))
                                  }
                                  onFocus={(e) =>
                                    setProdComPriceTo(removeCommas(e.target.value))
                                  }
                                />
                              </GridItem>
                            </GridItem>
                          </Grid>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>

                {/* 並び順 */}
                <Grid
                  h="60px"
                  //w="700px"
                  py="6"
                  // px="4"
                  templateRows="repeat(8, 1fr)"
                  templateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {/** 並び順 */}
                  <GridItem rowSpan={1} colSpan={4} bg="white">
                    <Text fontSize="20" w="100%">
                      並び順
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={8} bg="white">
                    <Select
                      // placeholder="選択してください"
                      borderRadius="0.5rem"
                      borderColor="#BBBBBB"
                      borderWidth="2px"
                      value={prodOrderCd1}
                      onChange={(e) => setProdOrderCd1(e.target.value)}
                    >
                      <option value="1">商品コード順</option>
                      <option value="2">仕入れ価格の安い順</option>
                      <option value="3">仕入れ価格の高い順</option>
                      <option value="4">メーカー名順(あ〜わ)</option>
                      <option value="5">メーカー名順(わ〜あ)</option>
                      <option value="6">ジャンル順(あ〜わ)</option>
                      <option value="7">ジャンル順(わ〜あ)</option>
                    </Select>
                  </GridItem>
                </Grid>
                {/* 検索ボタン */}
                <Flex align="center" py="6" justify="space-between">
                  {/* <Box p="2" bg=""></Box>
                  <Spacer /> */}
                  <Box flex="1">
                    {messageSerachProduct && (
                      <Box color="red" marginTop="10px">
                        {messageSerachProduct}
                      </Box>
                    )}
                  </Box>
                  <Button
                    isDisabled={progressbarFlg}
                    bg="blue.500"
                    color="white"
                    pl={8}
                    leftIcon={
                      progressbarFlg ? (
                        <CircularProgress
                          isIndeterminate
                          size="30"
                          color="white"
                        />
                      ) : (
                        <SearchIcon ml={2} />
                      )
                    }
                    _hover={{ bg: "blue.600" }}
                    onClick={onClickSearch1}
                  >
                    商品検索　
                  </Button>
                </Flex>
              </TabPanel>
              {/* 商品検索パネル */}
              <TabPanel>
                <Grid
                  h="120px"
                  //w="700px"
                  py="4"
                  templateRows="repeat(8, 1fr)"
                  templateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {/** 商品名 */}
                  <GridItem rowSpan={1} colSpan={3} bg="white">
                    <Text fontSize="20" w="100%">
                      商品名
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={9}>
                    <GridItem rowSpan={1} colSpan={9} bg="white">
                      <Input
                        maxLength={40}
                        type="text"
                        fontSize="20"
                        w="100%"
                        size="sm"
                        borderRadius="0.5rem"
                        borderColor="#BBBBBB"
                        borderWidth="2px"
                        value={prodProductName}
                        onChange={(e) => setProdProductName(e.target.value)}
                      />
                    </GridItem>
                  </GridItem>
                  {/** JANコード */}
                  <GridItem rowSpan={1} colSpan={3} bg="white">
                    <Text fontSize="20" w="100%">
                      JANコード
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={9} bg="white">
                    <Input
                      maxLength={13}
                      type="text"
                      fontSize="20"
                      w="100%"
                      size="sm"
                      borderRadius="0.5rem"
                      borderColor="#BBBBBB"
                      borderWidth="2px"
                      value={prodJanCode}
                      onChange={(e) => setProdJanCode(e.target.value)}
                    />
                  </GridItem>
                  {/** ASIN */}
                  <GridItem rowSpan={1} colSpan={3} bg="white">
                    <Text fontSize="20" w="100%">
                      ASIN
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={9} bg="white">
                    <Input
                      maxLength={10}
                      type="text"
                      fontSize="20"
                      w="100%"
                      size="sm"
                      borderRadius="0.5rem"
                      borderColor="#BBBBBB"
                      borderWidth="2px"
                      value={prodAsin}
                      onChange={(e) => setProdAsin(e.target.value)}
                    />
                  </GridItem>
                </Grid>
                {/* 並び順 */}
                <Grid
                  h="60px"
                  //w="700px"
                  py="6"
                  // px="4"
                  templateRows="repeat(8, 1fr)"
                  templateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {/** 並び順 */}
                  <GridItem rowSpan={1} colSpan={3} bg="white">
                    <Text fontSize="20" w="100%">
                      並び順
                    </Text>
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={9} bg="white">
                    <Select
                      // placeholder="選択してください"
                      borderRadius="0.5rem"
                      borderColor="#BBBBBB"
                      borderWidth="2px"
                      value={prodOrderCd2}
                      onChange={(e) => setProdOrderCd2(e.target.value)}
                    >
                      <option value="1">商品コード順</option>
                      <option value="2">仕入れ価格の安い順</option>
                      <option value="3">仕入れ価格の高い順</option>
                      <option value="4">メーカー名順(あ〜わ)</option>
                      <option value="5">メーカー名順(わ〜あ)</option>
                      <option value="6">ジャンル順(あ〜わ)</option>
                      <option value="7">ジャンル順(わ〜あ)</option>
                    </Select>
                  </GridItem>
                </Grid>
                {/* 検索ボタン */}
                <Flex align="center" py="6">
                  <Box p="2" bg=""></Box>
                  <Spacer />
                  <Button
                    isDisabled={progressbarFlg}
                    bg="blue.500"
                    color="white"
                    pl={4}
                    leftIcon={
                      progressbarFlg ? (
                        <CircularProgress
                          isIndeterminate
                          size="30"
                          color="white"
                        />
                      ) : (
                        <SearchIcon ml={4} />
                      )
                    }
                    _hover={{ bg: "blue.600" }}
                    onClick={onClickSearch2}
                  >
                    商品検索　
                  </Button>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>

          {/** 検索結果作成中 */}
          <Box p={4}>
            {errorMessage && (
              <Center marginTop="0px" marginBottom="30px">
                <Box color="red">{errorMessage}</Box>
              </Center>
            )}
            {searchResultCount > 0 && (
              <div>
                <PaginationInfo />
                <Flex justify="flex-end">
                  <Pagination />
                </Flex>
              </div>
            )}

            <Box>
              {/* 検索結果 */}
              {progressbarFlg ? (
                <Flex justify="center" align="center" height="200px">
                  <CircularProgress isIndeterminate color="blue.300" />
                </Flex>
              ) : (
                searchResultCount > 0 && (
                  <Box>
                    {/* <div>検索結果：{searchResultCount}件</div>
                  <Pagination /> */}
                    <div>
                      <table style={{ margin: "0 auto" }}>
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={cssOneline}
                              className="header-cell"
                            ></th>
                            <th
                              scope="col"
                              style={cssOneline}
                              className="header-cell"
                            >
                              商品ID
                            </th>
                            <th
                              scope="col"
                              style={cssOneline}
                              className="header-cell"
                            >
                              商品名
                            </th>
                            <th
                              scope="col"
                              style={cssOneline}
                              className="header-cell"
                            >
                              <Flex align="center">
                                <Text>仕入単価</Text>
                                <Tooltip
                                  hasArrow
                                  label="別途送料が発生します。"
                                  bg="blue.500"
                                  aria-label="A tooltip"
                                  placement="top"
                                  fontSize="md"
                                >
                                  <WarningIcon
                                    boxSize={4}
                                    color="red.500"
                                    ml={2}
                                  />
                                </Tooltip>
                              </Flex>
                            </th>
                            <th
                              scope="col"
                              style={cssOneline}
                              className="header-cell"
                            >
                              URL
                            </th>
                          </tr>
                        </thead>
                        <tbody className="" id="searchResultTable">
                          {searchResult.map((result, index) => (
                            <SearchResultRow
                              key={index}
                              result={result}
                              lastElementRef={null}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Flex justify="flex-end">
                      <Pagination />
                    </Flex>
                  </Box>
                )
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
});

export default SearchProduct;
