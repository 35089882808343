import React, { ChangeEvent, useState, memo } from "react";
import {
  Box,
  Container,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Text,
  VStack,
  CircularProgress,
  Tooltip,
  Stack,
  Divider,
  HStack
} from "@chakra-ui/react";
import { ArrowForwardIcon, QuestionIcon } from "@chakra-ui/icons";
import Header from "./organism/Header";
import { useAuth } from "../hooks/use-auth";
import { useNavigate } from "react-router-dom";

const ForgotPassword1SendCode: React.FC = memo(() => {
  const [userid, setUserid] = useState("");
  const [message, setMessage] = useState("");
  //現在のパスワードの入力内容が適切かどうかを管理
  //true：有効なパスワード
  //false：無効なパスワード
  const [validUserID, setValidUserid] = useState(false);
  const auth = useAuth();
  const [progressbarFlg, setProgressbarFlg] = useState<boolean>(false);
  const navigate = useNavigate();

  //ユーザーIDの入力内容に変更があった場合にユーザーIDを更新する
  const onchangeUserid = (e: ChangeEvent<HTMLInputElement>) => {
    const wkUserid = e.target.value;
    setUserid(wkUserid);
    //ユーザーIDが空かどうかチェックする
    setValidUserid(wkUserid === "" ? false : true);
  };

  const onClickReturnLogin = () => {
    navigate({ pathname: "/" });
  }

  const onClickNext = async () => {
    setProgressbarFlg(true);
    setMessage("");
    // コードを送信する
    const result = await auth.forgotPassword1SendCode(userid);
    console.log(result);
    if (result.success) {
      setMessage("リセットコードの送信が完了しました。");
      navigate({ pathname: "/forgotPassword2ChangePassword" });
    } else {
      console.log(result.message);
      setMessage("パスワードのリセットに失敗しました。");
    }
    setProgressbarFlg(false);
  };

  return (
    <>
      <Container py={{ base: "12", md: "48" }} px={{ base: "0", sm: "8" }}>
        <Stack spacing={{ base: "2", md: "4" }} textAlign="center">
          <Header>パスワード再設定</Header>
          <Divider />
        </Stack>
        <Box
          m={2}
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          borderWidth={1}
          borderRadius="md"
          shadow="md"
          bg={{ base: "white", sm: "bg-surface" }}
        >
          <VStack spacing={8} align="stretch">
            <Text fontSize="20px" align="center">
              ログインIDを入力してください。
            </Text>
            <FormControl id="userid" isRequired>
              <FormLabel>
                ログインID
                <Tooltip
                  label={
                    <>
                      ログインIDを忘れてしまった場合は、<br />
                      管理者にお問い合わせください、
                      <br />
                    </>
                  }
                >
                  <QuestionIcon boxSize={4} color="teal.400" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                type="text"
                value={userid}
                onChange={onchangeUserid}
                border="1px"
                borderColor="Brack"
              />
            </FormControl>
            {message && (
              <FormControl>
                <FormHelperText
                  color={message.includes("完了") ? "green.500" : "red.500"}
                >
                  {message}
                </FormHelperText>
              </FormControl>
            )}
            <Button
              bg="blue.500"
              color="white"
              _hover={{ bg: "blue.600" }}
              leftIcon={
                progressbarFlg ? (
                  <CircularProgress
                    isIndeterminate
                    size="30"
                    color="blue.500"
                  />
                ) : (
                  <ArrowForwardIcon boxSize={6}/>
                )
              }
              onClick={onClickNext}
              isDisabled={
                !validUserID || progressbarFlg
              }
            >
              次へ
            </Button>
            <HStack justify="end">
              <Button variant="link" colorScheme="blue" size="sm" onClick={onClickReturnLogin}>
                ログイン画面に戻る
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Container>
    </>
  );
});

export default ForgotPassword1SendCode;
