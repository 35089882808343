import React, { ChangeEvent, memo, useState, useRef } from "react";
import Header from "./organism/Header";
//import { PasswordField } from "./molecules/_PasswordField";
import { FaSignInAlt } from "react-icons/fa";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";
import {
  Box,
  Container,
  Divider,
  Input,
  Stack,
  FormControl,
  FormLabel,
  Button,
  HStack,
  InputGroup,
  InputRightElement,
  IconButton,
  useDisclosure,
  useMergeRefs,
  CircularProgress,
  Text,
} from "@chakra-ui/react";

const Login: React.FC = memo(() => {
  /** **** variables **** */
  const [userid, serUserid] = useState<string>("");

  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = React.createRef();
  const mergeRef = useMergeRefs(inputRef, ref);
  const [password, setPassword] = useState<string>("");
  const [progressbarFlg, setProgressbarFlg] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const auth = useAuth();
  const navigate = useNavigate();
  //console.log(auth.isAuthenticated)
  /** **** **** */

  /** **** event **** */
  //ユーザーIDの入力内容に変更があった場合にユーザIDを更新する
  const onchangeUserid = (e: ChangeEvent<HTMLInputElement>) => {
    serUserid(e.target.value);
    //console.log("useridに変更あり", userid);
  };

  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };
  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    //console.log("Passwordに変更あり", password);
  };

  const onClickPasswordForgot = () => {
    navigate({ pathname: "/forgotPassword1SendCode" });
  }

  const onClickLogin = async () => {
    setErrorMessage("");
    setProgressbarFlg(true);
    const result = await auth.signIn(userid, password);
    //console.log(result)
    if (result.success) {
      //初回ログイン時の考慮（challengeName："NEW_PASSWORD_REQUIRED"が設定される）
      if ("challengeName" in result && result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate({ pathname: "/forcePasswordChange" });
      }
      else {
        navigate({ pathname: "/top" });
      }
    } else {
      setErrorMessage(result.message);
    }
    setProgressbarFlg(false);
  };
  /** **** **** */

  return (
    <>
      <Container py={{ base: "12", md: "48" }} px={{ base: "0", sm: "8" }}>
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Header>ログイン</Header>
          <Divider />
        </Stack>
        <Box
          //as="form"
          shadow="md"
          borderRadius="md"
          bg={{ base: "white", sm: "bg-surface" }}
          m="2"
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
        >
          <Stack spacing="7">
            <Stack spacing="5">
              <FormControl isRequired>
                <FormLabel htmlFor="userid">ユーザID</FormLabel>
                <Input
                  id="userid"
                  type="userid"
                  border='1px'
                  borderColor="Brack"
                  value={userid}
                  onChange={onchangeUserid}
                  tabIndex={1}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="password">パスワード</FormLabel>
                <InputGroup>
                  <InputRightElement>
                    <IconButton
                      variant="link"
                      aria-label={isOpen ? "Mask password" : "Reveal password"}
                      icon={isOpen ? <HiEyeOff /> : <HiEye />}
                      onClick={onClickReveal}
                      tabIndex={4}
                    />
                  </InputRightElement>
                  <Input
                    id="password"
                    ref={mergeRef}
                    name="password"
                    border='1px'
                    borderColor="Brack"
                    type={isOpen ? "text" : "password"}
                    //autoComplete="current-password"
                    required
                    value={password}
                    onChange={onChangePassword}
                    tabIndex={2}
                  />
                </InputGroup>
              </FormControl>
            </Stack>
            <HStack justify="space-between">
              <Text color="red.500">{errorMessage}</Text>
              <Button variant="link" colorScheme="blue" size="sm" onClick={onClickPasswordForgot} tabIndex={5}>
                パスワードを忘れた場合
              </Button>
            </HStack>
            <Stack spacing="6">
              <Button
                isDisabled={progressbarFlg}
                bg="blue.500"
                color="white"
                _hover={{ bg: "blue.600" }}
                leftIcon={
                  progressbarFlg ? (
                    <CircularProgress
                      isIndeterminate
                      size="30"
                      color="blue.500"
                    />
                  ) : (
                    <FaSignInAlt />
                  )
                }
                onClick={onClickLogin}
                tabIndex={3}
              >
                ログイン
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </>
  );
});

export default Login;
