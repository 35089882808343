import React from "react";
import Navbar from "./molecules/Navbar";
import {
  Box,
  Text,
  Container,
  Divider,
  Link,
} from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";
import { Link as ReachLink } from "react-router-dom";

const QuotationComplete: React.FC = () => {
  // const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <Container
        pt={{ base: "4", md: "8" }}
        pb={{ base: "12", md: "20" }}
        px={{ base: "0", md: "0" }}
        maxW={{ base: "80%", md: "500px" }}
      >
        <Box
          //as="form"
          shadow="md"
          borderRadius="md"
          bg={{ base: "white", md: "bg-surface" }}
          //m="2"
          py={{ base: "0", md: "2" }}
          px={{ base: "4", md: "2" }}
        >
          <Text
            fontSize="14px"
            pt="10px"
            mb="10px"
            style={{ textAlign: "center" }}
          >
            商品情報の確認 ＞ ユーザ情報の確認 ＞ 入力情報の確認 ＞ 
            <span style={{ color: "red" }}>見積依頼完了</span>
          </Text>
          {/* <Text align="center">商品情報</Text> */}
          <Box h="5px"></Box>
          {/* <Divider borderColor="darkgray"></Divider> */}
          <Divider borderColor="darkgray" borderWidth="1.5px"></Divider>
          <Box h="10px"></Box>
          {/* カート情報の表示 */}
          <Text fontSize="1.2em" fontWeight="bold">
            見積依頼が完了しました
          </Text>

          {/* 線を少し太く */}
          {/* <Divider borderColor="darkgray" borderWidth="1.5px"></Divider> */}



          <Box textAlign="left" marginTop="4">
            <Link as={ReachLink} to="/searchProduct" style={{ textDecoration: 'underline', color: 'blue' }}>
              商品検索へ
            </Link>
          </Box>
          <Box h="10px"></Box>
        </Box>
      </Container>
    </>
  );
};

export default QuotationComplete;
