import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom'

interface ProtectedProps {
    isSignedIn: boolean;
    children: ReactNode;
  }

function Protected({ isSignedIn, children }:ProtectedProps) {
  //console.log("Protectedが呼ばれた")
  //console.log(isSignedIn)
  if (!isSignedIn) {
    return <Navigate to="/" replace />
  }
  return <>{children}</>
}
export default Protected