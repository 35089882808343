import { Amplify, Auth } from "aws-amplify";
import React, { createContext, useContext, useEffect, useState } from "react";
import AwsConfigAuth from "../aws-config/auth";

Amplify.configure({ Auth: AwsConfigAuth });

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  token:string;
  user:any
  signUp: (username: string, password: string) => Promise<Result>;
  confirmSignUp: (verificationCode: string) => Promise<Result>;
  signIn: (username: string, password: string) => Promise<Result>;
  signOut: () => void;
  completeNewPassword:(password: string) => Promise<Result>;
  changePassword:(oldPassword: string, newPassword: string)=> Promise<Result>
  forgotPassword1SendCode:(username: string)=> Promise<Result>
  ForgotPassword2ChangePassword:(code: string, newPassword: string)=> Promise<Result>
  currentAuthenticatedUser: () => Promise<Result>;
}

interface Result {
  success: boolean;
  message: string;
  challengeName?:string
  
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((result) => {
        //console.log("Auth.currentAuthenticatedUserの実行後：")
        //console.log(result.signInUserSession)

        setUsername(result.username);
        setToken(result.signInUserSession.idToken.jwtToken);
        //setToken("あいうえお")
        setUser(result)
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setUsername("");
        setToken("");
        setUser(null)
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  const currentAuthenticatedUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // console.log("user:" + user);
      // console.log("----------------")
      // console.log(user.signInUserSession)
      // console.log("----------------")
      setUser(user);
      setIsAuthenticated(true);
      setUsername(user.username);
      setToken(user.signInUserSession.idToken.jwtToken);
      return user;
    } catch (error) {
      setIsAuthenticated(false);
      console.error("ユーザー情報の取得に失敗しました。", error);
      return null;
    }
  };

  const signUp = async (username: string, password: string) => {
    try {
      await Auth.signUp({ username, password });
      setUsername(username);
      setPassword(password);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "認証に失敗しました。",
      };
    }
  };

  const confirmSignUp = async (verificationCode: string) => {
    try {
      await Auth.confirmSignUp(username, verificationCode);
      const result = await signIn(username, password);
      setPassword("");
      return result;
    } catch (error) {
      return {
        success: false,
        message: "認証に失敗しました。",
      };
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      const user = await Auth.signIn(username, password);
      const hasChallenge = Object.prototype.hasOwnProperty.call(user, 'challengeName')
      if (hasChallenge && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(user);
        return { success: true, message: "", challengeName: 'NEW_PASSWORD_REQUIRED'}
      }
      console.log("----------------")
      console.log(user.signInUserSession)
      console.log("----------------")
      setUsername(user.username);
      setIsAuthenticated(true);
      //setToken(user.signInUserSession.accessToken.jwtToken);
      setToken(user.signInUserSession.idToken.jwtToken);
      return { success: true, message: "" };
    } catch (error) {
      //console.log(error)
      return {
        success: false,
        message: "認証に失敗しました。",
      };
    }
  };

  const completeNewPassword = async (password: string) => {
    try {
      const loggedUser = await Auth.completeNewPassword(
        user, // the Cognito User Object
        password, // the new password
        // OPTIONAL, the required attributes
        {
        }
      );
      //console.log(loggedUser)
      setUsername(loggedUser.username);
      setIsAuthenticated(true);
      setToken(loggedUser.signInUserSession.idToken.jwtToken);
      setUser(null);
      //setToken()
      return { success: true, message: "" };
    } catch (error) {
      console.log(error)
      return {
        success: false,
        message: "認証に失敗しました。",
      };
    }
  };
  
  /**
   * 
   * @param username 
   * @returns 
   */
  const forgotPassword1SendCode = async (username: string) => {
    try {
      const data = await Auth.forgotPassword(username);
      //console.log(data)
      setUsername(username)
      return { success: true, message: "" };
    } catch (error) {
      console.log(error)
      return {
        success: false,
        message: "送信に失敗しました。",
      };
    }
  };

  /**
   * 
   * @param username 
   * @returns 
   */
  const ForgotPassword2ChangePassword = async (code: string, newPassword: string) => {
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
      //console.log(data)
      return { success: true, message: "" };
    } catch (error) {
      console.log(error)
      return {
        success: false,
        message: "パスワードの変更に失敗しました。",
      };
    }
  };
  
  /**
   * ログイン中のユーザーのパスワードを変更する
   * @param password 
   * @returns 
   */
  const changePassword = async (currentPassword: string, newPassword: string) => {
    try {
      const wkUser = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(wkUser, currentPassword, newPassword);
      
      //console.log(data)
      //setIsAuthenticated(true);
      return { success: true, message: "" };
    } catch (error) {
      console.log(error)
      return {
        success: false,
        message: "認証に失敗しました。",
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setIsLoading(false)
      setUsername("");
      setIsAuthenticated(false);
      setUser(null);
      setToken("")
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "ログアウトに失敗しました。",
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    username,
    token,
    user,
    signUp,
    confirmSignUp,
    signIn,
    signOut,
    completeNewPassword,
    changePassword,
    forgotPassword1SendCode,
    ForgotPassword2ChangePassword,
    currentAuthenticatedUser,
  };
};
