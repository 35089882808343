import React, { ChangeEvent, useState, memo } from "react";
import {
  Box,
  Container,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Text,
  VStack,
  CircularProgress,
  Flex,
  Tooltip,
  Stack,
  Divider,
  HStack,
  useToast
} from "@chakra-ui/react";
import { CheckCircleIcon, QuestionIcon } from "@chakra-ui/icons";

import { useAuth } from "../hooks/use-auth";
import { BsArrowCounterclockwise } from "react-icons/bs";
import Header from "./organism/Header";
import { useNavigate } from "react-router-dom";
import { isPasswordValid } from "../checker/checker";

const ForgotPassword2ChangePassword: React.FC = memo(() => {
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  //リセットコードの入力内容が適切かどうかを管理
  //true：有効なリセットコード
  //false：無効なリセットコード
  const [validResetCode, setValidResetCode] = useState(false);
  //新パスワードの入力内容が適切かどうかを管理
  const [validNewPassword, setValidNewPassword] = useState(false);
  //パスワード(確認)の入力内容が適切かどうかを管理
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);

  const auth = useAuth();
  const [progressbarFlg, setProgressbarFlg] = useState<boolean>(false);
  const navigate = useNavigate();

  //リセットコードの入力内容に変更があった場合にパスワードを更新する
  const onchangeResetCode = (e: ChangeEvent<HTMLInputElement>) => {
    const wkResetCode = e.target.value;
    setResetCode(wkResetCode);
    //パスワードの検証を行う
    setValidResetCode(wkResetCode === "" ? false : true);
  };

  //パスワードの入力内容に変更があった場合にパスワードを更新する
  const onchangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    //setNewPasswordにより設定したnewPasswordの値は、再レンダリング後に使用できるようになるため、
    //wkNewPasswordを定義した上でこれをメソッド内では利用するようにする。
    //(このメソッド内でnewPasswordを参照しても、前回の値を参照することになってしまう。)
    const wkNewPassword = e.target.value;
    setNewPassword(wkNewPassword);
    //パスワードの検証を行う
    setValidNewPassword(isPasswordValid(wkNewPassword) ? true : false);
  };

  //パスワード(確認)の入力内容に変更があった場合にパスワード(確認)を更新する
  const onchangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    //onchangeNewPasswordと同様の理由でwkConfirmPasswordを定義する。
    const wkConfirmPassword = e.target.value;
    setConfirmPassword(wkConfirmPassword);
    //パスワードの検証を行う
    setValidConfirmPassword(isPasswordValid(wkConfirmPassword) ? true : false);
  };

  const onClickReturnLogin = () => {
    navigate({ pathname: "/" });
  }

  const toast = useToast()
  const handleChangePassword = async () => {
    setProgressbarFlg(true);
    setMessage("");
    if (newPassword === confirmPassword) {
      //pass
    } else {
      setMessage("新しいパスワードと確認パスワードが一致しません。");
      setProgressbarFlg(false);
      return;
    }

    // パスワード変更の処理を行う
    const result = await auth.ForgotPassword2ChangePassword(resetCode, newPassword);
    console.log(result);
    if (result.success) {
      //setMessage("パスワードの変更が完了しました。");
      //toastを用いてパスワードの変更完了を通知する
      toast({
        title: "パスワードの変更が完了しました。",
        position: "top",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate({ pathname: "/" });
    } else {
      console.log(result.message);
      setMessage("パスワードの変更に失敗しました。");
    }
    setProgressbarFlg(false);
  };

  return (
    <>
      <Container py={{ base: "12", md: "48" }} px={{ base: "0", sm: "8" }}>
        <Stack spacing={{ base: "2", md: "4" }} textAlign="center">
          <Header>パスワード再設定</Header>
          <Divider />
        </Stack>
        <Box
          m={2}
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          borderWidth={1}
          borderRadius="md"
          shadow="md"
          bg={{ base: "white", sm: "bg-surface" }}
        >
          <Text fontSize="20px" align="left">
            ご登録済みのメールアドレスにリセットコードを送信しました。
          </Text>
          <br />
          <Flex
            direction="column"
            align="start"
            backgroundColor={"gray.50"}
            p={4}
          >
            <Flex align="center">
              <Text>パスワードの条件：</Text>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>10文字以上</Text>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>少なくとも 1 つの数字を含む</Text>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>少なくとも 1 つの特殊文字を含む</Text>
              <Tooltip
                label={
                  <>
                    特殊文字について
                    <br />
                    <br />
                    次の文字は特殊文字としてカウントされます。
                    <br />^ $ * . [ ] {} ( ) ? - &quot; ! @ # % &amp; / \ , &gt;
                    &lt; <br />
                    &apos; : ; | _ ~ ` + =
                  </>
                }
              >
                <QuestionIcon boxSize={4} color="teal.400" ml={2} />
              </Tooltip>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>少なくとも 1 つの大文字を含む</Text>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>少なくとも 1 つの小文字を含む</Text>
            </Flex>
          </Flex>
          <br />
          <VStack spacing={4} align="stretch">
            <FormControl id="resetCode" isRequired>
              <FormLabel>
                {
                  <CheckCircleIcon
                    boxSize={4}
                    color={validResetCode ? "green.500" : "red.500"}
                    mr={2}
                  />
                }
                リセットコード
              </FormLabel>
              <Input
                type="text"
                value={resetCode}
                onChange={onchangeResetCode}
                border="1px"
                borderColor="Brack"
              />
            </FormControl>
            <FormControl id="newPassword" isRequired>
              <FormLabel>
                {
                  <CheckCircleIcon
                    boxSize={4}
                    color={validNewPassword ? "green.500" : "red.500"}
                    mr={2}
                  />
                }
                新しいパスワード
              </FormLabel>
              <Input
                type="password"
                value={newPassword}
                onChange={onchangeNewPassword}
                border="1px"
                borderColor="Brack"
              />
            </FormControl>
            <FormControl id="confirmPassword" isRequired>
              <FormLabel>
                {
                  <CheckCircleIcon
                    boxSize={4}
                    color={validConfirmPassword ? "green.500" : "red.500"}
                    mr={2}
                  />
                }
                新しいパスワード（確認）
              </FormLabel>
              <Input
                type="password"
                value={confirmPassword}
                onChange={onchangeConfirmPassword}
                border="1px"
                borderColor="Brack"
              />
            </FormControl>
            {message && (
              <FormControl>
                <FormHelperText
                  color={message.includes("完了") ? "green.500" : "red.500"}
                >
                  {message}
                </FormHelperText>
              </FormControl>
            )}
            {/* <Button colorScheme="blue" onClick={handleChangePassword}>
              変更
            </Button> */}
            <Button
              bg="blue.500"
              color="white"
              _hover={{ bg: "blue.600" }}
              leftIcon={
                progressbarFlg ? (
                  <CircularProgress
                    isIndeterminate
                    size="30"
                    color="blue.500"
                  />
                ) : (
                  <BsArrowCounterclockwise size="20" />
                )
              }
              onClick={handleChangePassword}
              isDisabled={
                !validResetCode ||
                !validNewPassword ||
                !validConfirmPassword ||
                progressbarFlg
              }
            >
              変更
            </Button>
            <HStack justify="end">
              <Button variant="link" colorScheme="blue" size="sm" onClick={onClickReturnLogin}>
                ログイン画面に戻る
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Container>
    </>
  );
});

export default ForgotPassword2ChangePassword;
