import React, { ChangeEvent, useState, memo } from "react";
import Header from "./organism/Header";
import {
  Box,
  Container,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Text,
  VStack,
  CircularProgress,
  Flex,
  Tooltip,
  useToast,
  Stack,
  Divider,
} from "@chakra-ui/react";
import { CheckCircleIcon, QuestionIcon } from "@chakra-ui/icons";
import { useAuth } from "../hooks/use-auth";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { isPasswordValid } from "../checker/checker";
import Navbar from "../components/molecules/Navbar";

const CurrentPasswordChange: React.FC = memo(() => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  //現在のパスワードの入力内容が適切かどうかを管理
  //true：有効なパスワード
  //false：無効なパスワード
  const [validCurrentPassword, setValidCurrentPassword] = useState(false);
  //新パスワードの入力内容が適切かどうかを管理
  const [validNewPassword, setValidNewPassword] = useState(false);
  //パスワード(確認)の入力内容が適切かどうかを管理
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);

  const auth = useAuth();
  const [progressbarFlg, setProgressbarFlg] = useState<boolean>(false);

  //現在のパスワードの入力内容に変更があった場合にパスワードを更新する
  const onchangeCurrentPassword = (e: ChangeEvent<HTMLInputElement>) => {
    const wkCurrentPassword = e.target.value;
    setCurrentPassword(wkCurrentPassword);
    //パスワードの検証を行う
    setValidCurrentPassword(isPasswordValid(wkCurrentPassword) ? true : false);
  };

  //パスワードの入力内容に変更があった場合にパスワードを更新する
  const onchangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    //setNewPasswordにより設定したnewPasswordの値は、再レンダリング後に使用できるようになるため、
    //wkNewPasswordを定義した上でこれをメソッド内では利用するようにする。
    //(このメソッド内でnewPasswordを参照しても、前回の値を参照することになってしまう。)
    const wkNewPassword = e.target.value;
    setNewPassword(wkNewPassword);
    //パスワードの検証を行う
    setValidNewPassword(isPasswordValid(wkNewPassword) ? true : false);
  };

  //パスワード(確認)の入力内容に変更があった場合にパスワード(確認)を更新する
  const onchangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    //onchangeNewPasswordと同様の理由でwkConfirmPasswordを定義する。
    const wkConfirmPassword = e.target.value;
    setConfirmPassword(wkConfirmPassword);
    //パスワードの検証を行う
    setValidConfirmPassword(isPasswordValid(wkConfirmPassword) ? true : false);
  };

  const toast = useToast();
  const handleChangePassword = async () => {
    setProgressbarFlg(true);
    setMessage("");
    if (newPassword === confirmPassword) {
      //pass
    } else {
      setMessage("新しいパスワードと確認パスワードが一致しません。");
      setProgressbarFlg(false);
      return;
    }

    // パスワード変更の処理を行う
    const result = await auth.changePassword(currentPassword, newPassword);
    console.log(result);
    if (result.success) {
      //setMessage("パスワードの変更が完了しました。");
      setMessage("");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      toast({
        title: "パスワードの変更が完了しました。",
        position: "top",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setValidCurrentPassword(false);
      setValidNewPassword(false);
      setValidConfirmPassword(false);
      //navigate({ pathname: "/top" });
    } else {
      console.log(result.message);
      setMessage("パスワードの変更に失敗しました。");
    }
    setProgressbarFlg(false);
  };

  return (
    <>
      <Navbar />
      {/* <Heading fontSize="30px">パスワード変更</Heading> */}
      <br />
      <br />
      <br />
      {/* <Text fontSize="20px" align="center">
        パスワードを変更してください
      </Text> */}
      <Container maxW="lg" centerContent>
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Header>パスワード変更</Header>
          <Divider />
        </Stack>
        <Box
          m={2}
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          borderWidth={1}
          borderRadius="md"
          shadow="md"
          bg={{ base: "white", sm: "bg-surface" }}
        >
          <Flex
            direction="column"
            align="start"
            backgroundColor={"gray.50"}
            p={4}
          >
            <Flex align="center">
              <Text>パスワードの条件：</Text>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>10文字以上</Text>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>少なくとも 1 つの数字を含む</Text>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>少なくとも 1 つの特殊文字を含む</Text>
              <Tooltip
                label={
                  <>
                    特殊文字について
                    <br />
                    <br />
                    次の文字は特殊文字としてカウントされます。
                    <br />^ $ * . [ ] {} ( ) ? - &quot; ! @ # % &amp; / \ , &gt;
                    &lt; <br />
                    &apos; : ; | _ ~ ` + =
                  </>
                }
              >
                <QuestionIcon boxSize={4} color="teal.400" ml={2} />
              </Tooltip>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>少なくとも 1 つの大文字を含む</Text>
            </Flex>
            <Flex align="center">
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <CheckCircleIcon boxSize={4} color="green.500" mr={2} />
              <Text>少なくとも 1 つの小文字を含む</Text>
            </Flex>
          </Flex>

          <br />
          <VStack spacing={4} align="stretch">
            <FormControl id="currentPassword" isRequired>
              <FormLabel>
                {
                  <CheckCircleIcon
                    boxSize={4}
                    color={validCurrentPassword ? "green.500" : "red.500"}
                    mr={2}
                  />
                }
                現在のパスワード
              </FormLabel>
              <Input
                type="password"
                value={currentPassword}
                onChange={onchangeCurrentPassword}
                border="1px"
                borderColor="Brack"
              />
            </FormControl>
            <FormControl id="newPassword" isRequired>
              <FormLabel>
                {
                  <CheckCircleIcon
                    boxSize={4}
                    color={validNewPassword ? "green.500" : "red.500"}
                    mr={2}
                  />
                }
                新しいパスワード
              </FormLabel>
              <Input
                type="password"
                value={newPassword}
                onChange={onchangeNewPassword}
                border="1px"
                borderColor="Brack"
              />
            </FormControl>
            <FormControl id="confirmPassword" isRequired>
              <FormLabel>
                {
                  <CheckCircleIcon
                    boxSize={4}
                    color={validConfirmPassword ? "green.500" : "red.500"}
                    mr={2}
                  />
                }
                新しいパスワード（確認）
              </FormLabel>
              <Input
                type="password"
                value={confirmPassword}
                onChange={onchangeConfirmPassword}
                border="1px"
                borderColor="Brack"
              />
            </FormControl>
            {message && (
              <FormControl>
                <FormHelperText
                  color={message.includes("完了") ? "green.500" : "red.500"}
                >
                  {message}
                </FormHelperText>
              </FormControl>
            )}
            {/* <Button colorScheme="blue" onClick={handleChangePassword}>
              変更
            </Button> */}
            <Button
              bg="blue.500"
              color="white"
              _hover={{ bg: "blue.600" }}
              leftIcon={
                progressbarFlg ? (
                  <CircularProgress
                    isIndeterminate
                    size="30"
                    color="blue.500"
                  />
                ) : (
                  <BsArrowCounterclockwise size="20" />
                )
              }
              onClick={handleChangePassword}
              isDisabled={
                !validCurrentPassword ||
                !validNewPassword ||
                !validConfirmPassword ||
                progressbarFlg
              }
            >
              変更
            </Button>
          </VStack>
        </Box>
      </Container>
    </>
  );
});

export default CurrentPasswordChange;
