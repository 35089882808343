import React, { useState, useEffect } from "react";
import Navbar from "./molecules/Navbar";
import {
  Box,
  Text,
  Container,
  Button,
  Divider,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../hooks/use-auth";
import hashFunctionSHA256 from "../api/hash";
import { Link } from "react-router-dom";

const UserInfoConfirm: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [progressbarFlg, setProgressbarFlg] = useState(false);

  const handleNext = async () => {
    //サーバーからユーザーの現時点の認証情報を取得
    await auth.currentAuthenticatedUser();
    //認証切れの場合はログイン画面へ遷移
    if (!auth.isAuthenticated) {
      toast({
        title: "タイムアウトしました。",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      navigate({ pathname: "/" });
      return ;
    }
    //入力チェック
    if (userName === "") {
      setErrorMessage("お名前を入力してください");
      return;
    }
    if (phone1 === "" || phone2 === "" || phone3 === "") {
      setErrorMessage("電話番号を入力してください");
      return;
    }
    // 電話番号が半角英数でない場合、エラー
    if (
      !/^\d{2,5}$/.test(phone1) ||
      !/^\d{1,4}$/.test(phone2) ||
      !/^\d{4}$/.test(phone3)
    ) {
      setErrorMessage("電話番号は半角数字で入力してください");
      return;
    }
    if (email === "") {
      setErrorMessage("メールアドレスを入力してください");
      return;
    }
    //メールアドレスを正規表現でチェック
    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
      setErrorMessage("メールアドレスの形式が正しくありません");
      return;
    }
    if (zip1 === "" || zip2 === "") {
      setErrorMessage("郵便番号を入力してください");
      return;
    }
    //郵便番号を正規表現でチェック
    if (!/^\d{3}$/.test(zip1) || !/^\d{4}$/.test(zip2)) {
      setErrorMessage("郵便番号は半角数字で入力してください");
      return;
    }
    if (address1 === "") {
      setErrorMessage("都道府県を選択してください");
      return;
    }
    if (address2 === "") {
      setErrorMessage("市区町村を入力してください");
      return;
    }
    //建物名が正しい値であること
    // if (address3 === "") {
    //   setErrorMessage("建物名を入力してください");
    //   return;
    // }

    setErrorMessage("");
    //sessionStorageに保存
    sessionStorage.setItem("userName", userName);
    sessionStorage.setItem("phone1", phone1);
    sessionStorage.setItem("phone2", phone2);
    sessionStorage.setItem("phone3", phone3);
    sessionStorage.setItem("email", email);
    sessionStorage.setItem("zip1", zip1);
    sessionStorage.setItem("zip2", zip2);
    sessionStorage.setItem("address1", address1);
    sessionStorage.setItem("address2", address2);
    sessionStorage.setItem("address3", address3);

    //カート内の変更を検知するためのHashを更新
    const cartDataListHashBefore = sessionStorage.getItem("cartDataListHash");
    //console.log(cartDataListHashBefore);
    const cartDataListStr = localStorage.getItem("cartDataList") || "[]";
    const cartDataListHashAfter = await hashFunctionSHA256(cartDataListStr);
    if (cartDataListHashBefore !== cartDataListHashAfter) {
      console.log("カート内の変更を検知しました");
      toast({
        title: "カートが変更されました。",
        position: "top",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      //ListingCartへ遷移
      navigate({ pathname: "/cart" });
      return;
    }
    //カートに変更がなければsessionに保存
    sessionStorage.setItem("cartDataListHash", cartDataListHashAfter);

    // ここに次のアクションを定義
    navigate({ pathname: "/inputinfoconfirm" });
  };

  const [prefectures] = useState<string[]>([
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ]);

  const [userName, setUserName] = useState("");
  // const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [zip1, setZip1] = useState("");
  const [zip2, setZip2] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");

  const [zipErrorMessage, setZipErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddressSearch = async () => {
    setProgressbarFlg(true);
    const zip = zip1 + zip2;

    try {
      const response = await axios.get(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zip}`
      );
      const data = response.data;
      //console.log(data);
      if (data.results && data.results.length > 0) {
        const result = data.results[0];
        console.log(result);
        setAddress1(result.address1);
        setAddress2(result.address2 + result.address3);
        //setAddress(`${result.address1}${result.address2}${result.address3}`);
      } else {
        setZipErrorMessage("該当する住所が見つかりませんでした。");
      }
    } catch (error) {
      setZipErrorMessage("住所の検索に失敗しました。");
    } finally {
      setProgressbarFlg(false);
    }
  };

  useEffect(() => {
    const fetchUserData = () => {
      const userName = sessionStorage.getItem("userName") || "";
      const phone1 = sessionStorage.getItem("phone1") || "";
      const phone2 = sessionStorage.getItem("phone2") || "";
      const phone3 = sessionStorage.getItem("phone3") || "";
      const email = sessionStorage.getItem("email") || "";
      const zip1 = sessionStorage.getItem("zip1") || "";
      const zip2 = sessionStorage.getItem("zip2") || "";
      const address1 = sessionStorage.getItem("address1") || "";
      const address2 = sessionStorage.getItem("address2") || "";
      const address3 = sessionStorage.getItem("address3") || "";
      setUserName(userName);
      setPhone1(phone1);
      setPhone2(phone2);
      setPhone3(phone3);
      setEmail(email);
      setZip1(zip1);
      setZip2(zip2);
      setAddress1(address1);
      setAddress2(address2);
      setAddress3(address3);
    };
    fetchUserData();
  }, []);

  return (
    <>
      <Navbar />
      <Container
        pt={{ base: "4", md: "8" }}
        pb={{ base: "12", md: "20" }}
        px={{ base: "0", md: "0" }}
        maxW={{ base: "80%", md: "500px" }}
      >
        <Box
          //as="form"
          shadow="md"
          borderRadius="md"
          bg={{ base: "white", md: "bg-surface" }}
          //m="2"
          py={{ base: "0", md: "2" }}
          px={{ base: "4", md: "2" }}
        >
          <Text
            fontSize="14px"
            pt="10px"
            mb="10px"
            style={{ textAlign: "center" }}
          >
            商品情報の確認 ＞
            <span style={{ color: "red" }}> ユーザ情報の確認 </span> ＞
            入力情報の確認 ＞ 見積依頼完了
          </Text>
          {/* <Text align="center">商品情報</Text> */}
          <Box h="5px"></Box>
          {/* <Divider borderColor="darkgray"></Divider> */}
          <Divider borderColor="darkgray" borderWidth="1.5px"></Divider>
          <Box h="10px"></Box>
          {/* 入力項目を用意する */}
          {/* お名前 */}
          <FormControl isRequired>
            <FormLabel htmlFor="name">お名前</FormLabel>
            <Input
              type="text"
              id="name"
              name="name"
              size="sm"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </FormControl>
          <Box h="10px"></Box>
          {/* 電話番号 */}
          <FormControl isRequired>
            <FormLabel htmlFor="phone">電話番号</FormLabel>

            <Flex justifyContent="space-between">
              <Input
                type="tel"
                placeholder="000"
                maxLength={5}
                id="phone1"
                name="phone1"
                size="sm"
                mr={2}
                value={phone1}
                onChange={(e) => setPhone1(e.target.value)}
              />
              ー
              <Input
                type="tel"
                placeholder="000"
                maxLength={4}
                id="phone2"
                name="phone2"
                size="sm"
                ml={2}
                mr={2} // 右のマージン
                value={phone2}
                onChange={(e) => setPhone2(e.target.value)}
              />
              ー
              <Input
                type="tel"
                placeholder="0000"
                maxLength={4}
                id="phone3"
                name="phone3"
                size="sm"
                ml={2} // 左のマージン
                value={phone3}
                onChange={(e) => setPhone3(e.target.value)}
              />
            </Flex>
          </FormControl>
          <Box h="10px"></Box>
          {/* メールアドレス */}
          <FormControl isRequired>
            <FormLabel htmlFor="email">メールアドレス</FormLabel>
            <Input
              type="email"
              id="email"
              name="email"
              size="sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Box h="10px"></Box>
          {/* (仕入時)送付先住所(郵便番号、住所1、住所2) */}
          <FormControl isRequired>
            <FormLabel htmlFor="zip">郵便番号</FormLabel>
            <Flex justifyContent="space-between">
              <Input
                placeholder="123"
                maxLength={3}
                size="sm"
                mr={2} // 右のマージン
                value={zip1}
                onChange={(e) => setZip1(e.target.value)}
              />
              ー
              <Input
                placeholder="4567"
                maxLength={4}
                size="sm"
                ml={2} // 左のマージン
                value={zip2}
                onChange={(e) => setZip2(e.target.value)}
              />
              <Button
                isDisabled={progressbarFlg}
                colorScheme="blue"
                size="sm"
                ml="10px"
                w="200px"
                onClick={handleAddressSearch}
              >
                住所検索
              </Button>
            </Flex>
          </FormControl>
          <Box h="10px"></Box>
          <Text>{zipErrorMessage}</Text>
          <FormControl isRequired>
            <FormLabel htmlFor="address1">都道府県</FormLabel>
            <Select
              id="address1"
              name="address1"
              size="sm"
              placeholder="都道府県を選択してください"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
            >
              {prefectures.map((prefecture) => (
                <option key={prefecture} value={prefecture}>
                  {prefecture}
                </option>
              ))}
            </Select>
          </FormControl>
          <Box h="10px"></Box>
          <FormControl isRequired>
            <FormLabel htmlFor="address2">住所</FormLabel>
            <Input
              type="text"
              id="address2"
              name="address2"
              placeholder="市区町村"
              size="sm"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </FormControl>
          <Box h="10px"></Box>
          <FormControl>
            <FormLabel htmlFor="address3">建物名・部屋番号</FormLabel>
            <Input
              type="text"
              id="address3"
              name="address3"
              placeholder="建物名"
              size="sm"
              value={address3}
              onChange={(e) => setAddress3(e.target.value)}
            />
          </FormControl>
          <Box h="20px"></Box>
          {/* 次へ　ボタンを右寄せで表示 */}
          <Divider borderColor="darkgray" borderWidth="1.5px"></Divider>
          <Box h="10px"></Box>
          <Text color="red" style={{ textAlign: "right" }}>
            {errorMessage}
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link
              to="/cart"
              style={{
                color: "#3182CE",
                padding: "12px",
                fontSize: "sm",
                marginTop: "2",
                marginBottom: "2",
                width: "35%",
                display: "inline-block",
                textAlign: "center",
                textDecoration: "none", // 下線を引かない
              }}
              onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")} // カーソルが当たったら下線を引く
              onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")} // カーソルが離れたら下線を消す
            >
              商品情報を確認する
            </Link>
            <Button
              onClick={handleNext}
              colorScheme="blue"
              size="sm"
              mt="2"
              mb="2"
              width="30%" // 幅を設定する
            >
              次へ
            </Button>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default UserInfoConfirm;
