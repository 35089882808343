export const getCopyHeader = () => {
  return "自社管理ID" +
  "\t" +
  "ステータス" +
  "\t" +
  "カテゴリ" +
  "\t" +
  "JANコード" +
  "\t" +
  "購入制限数 単位(個以下)" +
  "\t" +
  "仕入単価(円)" +
  "\t" +
  "定価(円)" +
  "\t" +
  "実重量(g)" +
  "\t" +
  "縦(cm)" +
  "\t" +
  "横(cm)" +
  "\t" +
  "高さ(cm)" +
  "\t" +
  "容積重量(g)" +
  "\t" +
  "最終更新日時" +
  "\t" +
  "メーカー名" +
  "\t" +
  "国内EC2販売" +
  "\t" +
  "海外EC2販売" +
  "\t" +
  "国内ﾄﾞﾛｯﾌﾟｼｯﾌﾟ" +
  "\t" +
  "海外ﾄﾞﾛｯﾌﾟｼｯﾌﾟ" +
  "\t" +
  "仕入れ形態" +
  "\t" +
  "卸業者名" +
  "\t" +
  "送料無料条件" +
  "\t" +
  "Amazon.jp ASIN" +
  "\t" +
  "Amazon.jp URL" +
  "\t" +
  "Amazon.jp 出品可否" +
  "\t" +
  "Amazon.jp ランキング(位)" +
  "\t" +
  "Amazon.jp 単価(円)" +
  "\t" +
  "Amazon.com URL" +
  "\t" +
  "Amazon.com 出品可否" +
  "\t" +
  "Amazon.com ランキング(位)" +
  "\t" +
  "Amazon.com 単価($)" +
  "\t" +
  "shopify URL" +
  "\t" +
  "楽天 URL" +
  "\t" +
  "Yahoo! URL";
};