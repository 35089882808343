import React, { useState, useEffect } from "react";
import Navbar from "./molecules/Navbar";
import {
  Box,
  Text,
  Container,
  Button,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { CartData } from "../interface/CartData";
import { useNavigate } from "react-router-dom";
import hashFunctionSHA256 from "../api/hash";
import { useAuth } from "../hooks/use-auth";
import { Link } from "react-router-dom";

const ListingCart: React.FC = () => {
  const auth = useAuth();
  const toast = useToast();
  const [cartDataList, setCartDataList] = useState<{ [key: string]: CartData }>(
    {}
  );

  const handleRemoveFromCart = (key: string) => {
    //indexに該当するデータをcartDataListから削除する
    console.log(cartDataList);
    const newCartDataList = { ...cartDataList };
    delete newCartDataList[key];
    setCartDataList(newCartDataList);
    //localStorage.removeItem(key);
    localStorage.setItem("cartDataList", JSON.stringify(newCartDataList));
    window.dispatchEvent(new Event("cartDataUpdated"));
  };

  const navigate = useNavigate();
  const handleNext = async () => {
    //サーバーからユーザーの現時点の認証情報を取得
    await auth.currentAuthenticatedUser();
    //認証切れの場合はログイン画面へ遷移
    if (!auth.isAuthenticated) {
      toast({
        title: "タイムアウトしました。",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      navigate({ pathname: "/" });
      return;
    }
    //カート内の変更を検知するためのHashを更新
    const cartDataListHash = await hashFunctionSHA256(
      JSON.stringify(cartDataList)
    );
    console.log(cartDataListHash);
    sessionStorage.setItem("cartDataListHash", cartDataListHash);
    // ここに次のアクションを定義
    navigate({ pathname: "/userinfoconfirm" });
  };

  useEffect(() => {
    const fetchCartData = () => {
      try {
        // localStorageからカートデータを取得
        const cartDataList = JSON.parse(
          localStorage.getItem("cartDataList") || "[]"
        );
        setCartDataList(cartDataList);
      } catch (error) {
        console.error("カートデータの取得に失敗しました", error);
      }
    };

    fetchCartData();
  }, []);

  return (
    <>
      <Navbar />
      <Container
        pt={{ base: "4", md: "8" }}
        pb={{ base: "12", md: "20" }}
        px={{ base: "0", md: "0" }}
        maxW={{ base: "80%", md: "500px" }}
      >
        <Box
          //as="form"
          shadow="md"
          borderRadius="md"
          bg={{ base: "white", md: "bg-surface" }}
          //m="2"
          py={{ base: "0", md: "2" }}
          px={{ base: "4", md: "2" }}
        >
          <Text
            fontSize="14px"
            pt="10px"
            mb="10px"
            style={{ textAlign: "center" }}
          >
            <span style={{ color: "red" }}>商品情報の確認</span> ＞
            ユーザ情報の確認 ＞ 入力情報の確認 ＞ 見積依頼完了
          </Text>
          {/* <Text align="center">商品情報</Text> */}
          <Box h="5px"></Box>
          {/* <Divider borderColor="darkgray"></Divider> */}
          <Divider borderColor="darkgray" borderWidth="1.5px"></Divider>

          <Box h="5px"></Box>
          {Object.keys(cartDataList).length !== 0 ? (
            <div>
              {Object.entries(cartDataList).map(([key, value]) => (
                <div
                  key={key}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #cdcccc",
                    margin: "2px 0",
                  }}
                >
                  <Link
                    to={`/product?prodID=${value.prodID}&timestamp=${value.timestamp}`}
                    target="_blank"
                  >
                    <Box
                      w="100px"
                      h="100px"
                      bg="transparent"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt="4"
                      mb="2"
                    >
                      <img
                        src={value.imageURL}
                        alt={value.productName}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </Box>
                  </Link>
                  <div style={{ textAlign: "left" }}>
                    <span>{value.productName}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span style={{ color: "red" }}>
                      {value.purchasePrice}円
                    </span>
                    <span style={{ color: "red" }}>{value.quantity}個</span>
                    <Button
                      onClick={() => handleRemoveFromCart(key)}
                      colorScheme="gray"
                      bg="gray.500"
                      _hover={{ bg: "gray.600" }}
                      size="sm"
                      mt="2"
                      color="white" // テキスト色を白に
                    >
                      削除
                    </Button>
                  </div>
                </div>
              ))}
              {/* 合計の金額を表示 */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                  見積合計:&emsp;{" "}
                </span>
                <span
                  style={{
                    color: "red",
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  {Object.values(cartDataList)
                    .reduce(
                      (total: number, item: CartData) =>
                        total +
                        Number(item.purchasePrice) * Number(item.quantity),
                      0 as number
                    )
                    .toLocaleString()}
                  円
                </span>
              </div>
              {/* <Divider borderColor="darkgray"></Divider> */}
              <Divider borderColor="darkgray" borderWidth="1.5px"></Divider>

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={handleNext}
                  colorScheme="blue"
                  size="sm"
                  mt="2"
                  mb="2"
                  width="30%" // 幅を設定する
                >
                  次へ
                </Button>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  to="/top"
                  style={{
                    color: "#3182CE",
                    padding: "12px",
                    fontSize: "sm",
                    marginTop: "2",
                    marginBottom: "2",
                    width: "25%",
                    display: "inline-block",
                    textAlign: "center",
                    textDecoration: "none", // 下線を引かない
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.textDecoration = "underline")
                  } // カーソルが当たったら下線を引く
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.textDecoration = "none")
                  } // カーソルが離れたら下線を消す
                >
                  トップに戻る
                </Link>
                <Button
                  onClick={handleNext}
                  colorScheme="blue"
                  size="sm"
                  mt="2"
                  mb="2"
                  width="30%" // 幅を設定する
                >
                  次へ
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "5vh",
              }}
            >
              <span>カートに商品がありません。</span>
            </div>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ListingCart;
