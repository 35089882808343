
import {Text} from '@chakra-ui/react'

const NotFound = () => {
  return (
    <>
    <Text>このページは存在しません。</Text>
    </>
  )
}

export default NotFound