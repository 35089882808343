/**
 * 数値を3桁ごとにカンマ区切りの文字列に変換
 * @param value 文字列または数値
 */
const formatNumberWithComma = (value: string | number): string => {
  const num =
    typeof value === "string" ? value.replace(/,/g, "") : value.toString();
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * カンマを除去した文字列を返す
 * @param value 文字列
 */
const removeCommas = (value: string): string => {
  return value.replace(/,/g, "");
};


export { formatNumberWithComma, removeCommas };

