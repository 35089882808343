import { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Divider,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useAuth } from "../../hooks/use-auth";
import { useNavigate, Link as ReachLink } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";
// import { CartData } from "../../interface/CartData";
/**
 * リンクの各要素
 * toLink:画面リンクのパス
 * children:画面に表示するリンク名
 */
const NavLink = ({
  toLink,
  children,
}: {
  toLink: string;
  children: ReactNode;
}) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("blue.200", "blue.700"),
    }}
    as={ReachLink}
    to={toLink}
  >
    {children}
  </Link>
);

/**  各ページへのリンク
 *  ただし、トップページについてはロゴと紐づけるため、ここに記述しない
 */
const Navlinks = () => (
  <>
    <NavLink toLink="/searchProduct">商品検索</NavLink>
  </>
);

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const auth = useAuth();
  const navigate = useNavigate();

  const toast = useToast();
  //ログアウトボタン押下時の処理
  const onClickLogout = () => {
    //console.log("ログアウト");
    auth.signOut();
    toast({
      title: "ログアウトしました。",
      position: "top",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    navigate({ pathname: "/" });
  };

  //パスワード変更押下時
  const onClickCurrentPasswordChange = () => {
    navigate({ pathname: "/currentPasswordChange" });
  };

  const [cartDataLength, setCartDataLength] = useState(0);
  // ローカルストレージの変更を監視し、カートのアイテム数を更新する
  useEffect(() => {
    const handleStorageChange = () => {
      // ローカルストレージからカートデータを取得し、アイテム数を更新
      const cartDataList = JSON.parse(
        localStorage.getItem("cartDataList") || "[]"
      );

      //setCartDataList(cartDataList);
      const numberOfProducts = Object.keys(cartDataList).length;
      setCartDataLength(numberOfProducts);
      //setCartDataLength(Object.keys(cartDataList).length);
    };

    handleStorageChange(); // 初期表示時にもカートの中身を反映
    window.addEventListener("cartDataUpdated", handleStorageChange);

    // クリーンアップ関数：コンポーネントがアンマウントされるときにイベントリスナーを削除
    return () => {
      window.removeEventListener("cartDataUpdated", handleStorageChange);
    };
  }, []);

  // const [cartDataList, setCartDataList] = useState<{ [key: string]: CartData }>(
  //   {}
  // );
  const onCartIconClick = () => {
    navigate({ pathname: "/cart" });
  };
  const onImageClick = () => {
    navigate({ pathname: "/top" });
  };

  return (
    <>
      <Box
        bg={useColorModeValue("blue.400", "blue.900")}
        px={4}
        color="gray.50"
        fontWeight="bold"
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          {/* ハンバーガーの実装（横幅がmd以下の場合にハンバーガーに変更する） */}
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          {/* 画面左側のメニュー */}
          <HStack spacing={8} alignItems={"center"}>
            {/* ロゴ(TOPページのリンク)*/}
            <Box
              as="button"
              onClick={onImageClick}
              maxW="100px"
              maxH="100px"
              overflow="hidden"
              display={{ base: "none", md: "block" }}
            >
              <Image
                src="/Global_Wholesale_small_color.png"
                alt="Global Wholesale"
                objectFit="contain"
              />
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {/* {LinksMap.forEach((value, key) => {
                  <NavLink toLink={key}>{value}</NavLink>
                })} */}
              <Navlinks />
            </HStack>
          </HStack>
          <Box ml="auto" mr={8} position="relative">
            <IconButton
              onClick={onCartIconClick}
              icon={<FaShoppingCart size="24" />}
              aria-label="open-cart"
              colorScheme="blue.400"
            />
            <Box
              position="absolute"
              top="-1"
              right="-1"
              bg="red"
              w="20px"
              h="20px"
              borderRadius="50%"
            >
              <Text
                fontSize="xs"
                color="white"
                textAlign="center"
                lineHeight="20px"
              >
                {cartDataLength}
                {/* {Object.keys(cartDataList).length} */}
              </Text>
            </Box>
          </Box>
          {/* 画面右側のユーザ-ドロップダウンリスト */}
          <Flex alignItems={"center"}>
            <Menu>
              {/* ボタンのテキストにユーザー名を表示する */}
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
                color="gray.50"
              >
                {auth.username}
              </MenuButton>
              <MenuList bg="blue.400" p={1}>
                <MenuItem
                  onClick={onClickLogout}
                  bg="blue.400"
                  _hover={{ bg: "blue.500" }}
                >
                  ログアウト
                </MenuItem>
                <MenuDivider m={0} />
                <MenuItem
                  onClick={onClickCurrentPasswordChange}
                  bg="blue.400"
                  _hover={{ bg: "blue.500" }}
                >
                  パスワード変更
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        {/* ハンバーガーで折りたたんだ状態のメニュー*/}
        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {/* <NavLink toLink="/json">JsonSchemaTest</NavLink>
              <NavLink toLink="/searchProduct">商品検索</NavLink> */}
              <Navlinks />
            </Stack>
          </Box>
        ) : null}
      </Box>
      <Divider />
      <br></br>
    </>
  );
}
