import { useLocation } from "react-router-dom";
import { getCopyHeader } from "../getter/CopyHeader";
import Navbar from "./molecules/Navbar";
import {
  Box,
  Container,
  Button,
  CircularProgress,
  Text,
  Flex,
  Heading,
  Center,
  Divider,
  Table,
  Link,
  Tr,
  Tooltip,
  Tbody,
  Td,
  useTheme,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
} from "@chakra-ui/react";
import { QuestionIcon, CheckIcon, InfoIcon } from "@chakra-ui/icons";

import copy from "clipboard-copy";

import React, { memo, useEffect, useState, useRef } from "react";
// import CarouselImage from "./molecules/CarouselImage";
import axios from "axios";
import { useAuth } from "../hooks/use-auth";
import { API_URL_PRODUCT_DETAIL } from "../constants/constants";
import "../theme/style.css";
// import { stringify } from "querystring";
import { CartData } from "../interface/CartData";

/** css */
// const cssOneline: React.CSSProperties = {
//   whiteSpace: "nowrap",
// };

const ProductDetail: React.FC = memo(() => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  //const prodID = params.get("prodID");
  //const prodID: string = params.get("prodID");
  const prodID: string = params.get("prodID") || "";

  const timestamp = params.get("timestamp");
  //const year = timestamp.substring(0, 4)

  const auth = useAuth();

  const [progressbarFlg, setProgressbarFlg] = useState<boolean>(false);
  // const [errorMessage, setErrorMessage] = useState<string>("");

  //初期表示用のデータ取得結果
  const statusRef = useRef<string>("");
  const manufacturerRef = useRef<string>("");
  const imageURLRef = useRef<string>("");
  //const productPublishFlagRef = useRef<string>("");
  const productNameRef = useRef<string>("");
  const shopifyURLRef = useRef<string>("");
  const categoryRef = useRef<string>("");
  const janCodeRef = useRef<string>("");
  const asinRef = useRef<string>("");
  const amazonJPOfferableRef = useRef<string>("");
  const jpURLRef = useRef<string>("");
  const jpRankingRef = useRef<string>("");
  const jpPriceRef = useRef<string>("");
  const rakutenURLRef = useRef<string>("");
  const yahooURLRef = useRef<string>("");
  const amazonUSOfferableRef = useRef("");
  const comURLRef = useRef<string>("");
  const comRankingRef = useRef<string>("");
  const comPriceRef = useRef<string>("");
  const purchaseLimitRef = useRef<string>("");
  const purchasePriceRef = useRef<string>("");
  const regularPriceRef = useRef<string>("");
  const actualWeightGramsRef = useRef<string>("");
  const measurementRef = useRef<string>("");
  const lengthCMRef = useRef<string>("");
  const widthCMRef = useRef<string>("");
  const heightCMRef = useRef<string>("");
  const volumetricWeightGramsRef = useRef<string>("");
  const domesticAdmissibilityRef = useRef<string>("");
  const overseasAdmissibilityRef = useRef<string>("");
  const domesticDropShiipingRef = useRef<string>("");
  const overseasDropShiipingRef = useRef<string>("");
  const stockingPatternRef = useRef<string>("");
  const wholesalerNameRef = useRef<string>("");
  const freeShippingConditionRef = useRef<string>("");
  const purchaseOrderPatternRef = useRef<string>("");
  // const yearRef = useRef<string>("");
  const yearRef = useRef<string>(timestamp?.substring(0, 4) ?? "");
  const monthRef = useRef<string>(timestamp?.substring(4, 6) ?? "");
  const dayRef = useRef<string>(timestamp?.substring(6, 8) ?? "");

  // const monthRef = useRef<string>("");
  // const dayRef = useRef<string>("");

  const handleHeaderCopyClick = () => {
    const copyHeader = getCopyHeader();
    copy(copyHeader)
      .then(() => {
        setHeaderCopied(true);
        setTimeout(() => setHeaderCopied(false), 2000);
      })
      .catch((error) => console.error("コピーに失敗しました: ", error));
  };

  const handleCopyClick = () => {
    const textToCopy =
      prodID +
      "\t" +
      getStatusText() +
      "\t" +
      categoryRef.current +
      "\t" +
      janCodeRef.current +
      "\t" +
      purchaseLimitRef.current +
      "\t" +
      Number(purchasePriceRef.current).toLocaleString() +
      "\t" +
      Number(regularPriceRef.current).toLocaleString() +
      "\t" +
      Number(actualWeightGramsRef.current).toLocaleString() +
      "\t" +
      Number(lengthCMRef.current).toLocaleString() +
      "\t" +
      Number(widthCMRef.current).toLocaleString() +
      "\t" +
      Number(heightCMRef.current).toLocaleString() +
      "\t" +
      Number(volumetricWeightGramsRef.current).toLocaleString() +
      "\t" +
      parseInt(yearRef.current, 10) +
      "年" +
      parseInt(monthRef.current, 10) +
      "月" +
      parseInt(dayRef.current, 10) +
      "日" +
      "\t" +
      manufacturerRef.current +
      "\t" +
      getKaHiText(domesticAdmissibilityRef.current) +
      "\t" +
      getKaHiText(overseasAdmissibilityRef.current) +
      "\t" +
      getKaHiText(domesticDropShiipingRef.current) +
      "\t" +
      getKaHiText(overseasDropShiipingRef.current) +
      "\t" +
      getStockingPatternText() +
      "\t" +
      getWholesalerName() +
      "\t" +
      getFreeShippingConditionText() +
      "\t" +
      asinRef.current +
      "\t" +
      jpURLRef.current +
      "\t" +
      getKaHiText(amazonJPOfferableRef.current) +
      "\t" +
      Number(jpRankingRef.current).toLocaleString() +
      "\t" +
      Number(jpPriceRef.current).toLocaleString() +
      "\t" +
      comURLRef.current +
      "\t" +
      getKaHiText(amazonUSOfferableRef.current) +
      "\t" +
      Number(comRankingRef.current).toLocaleString() +
      "\t" +
      Number(comPriceRef.current).toLocaleString() +
      "\t" +
      shopifyURLRef.current +
      "\t" +
      rakutenURLRef.current +
      "\t" +
      yahooURLRef.current;

    copy(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((error) => console.error("コピーに失敗しました: ", error));
  };
  //コピーのフラグ
  const [copied, setCopied] = useState(false);
  const [headerCopied, setHeaderCopied] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [isOrderProcessing, setIsOrderProcessing] = useState(false);
  // const [orderDetails, setOrderDetails] = useState({
  //   name: "",
  //   address: "",
  //   quantity: "",
  // });
  const [quantity, setQuantity] = useState<string>("");
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [addedMessage, setAddedMessage] = useState<string>("");

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    setAddedMessage("");
  };

  // // フォームの入力値が変更されたときに呼び出される関数
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   // 入力値を状態に保存する
  //   setQuantity(e.target.value);
  // };
  // const handleSubmitOrder = async () => {
  //   setIsOrderProcessing(true);
  //   // ここでサーバーに注文処理をリクエストします。
  //   // 注文処理が完了したら、以下のように状態を更新します。
  //   setIsOrderProcessing(false);
  // };

  const getStatusText = () => {
    switch (statusRef.current) {
      case "1":
        return "在庫あり";
      case "2":
        return "欠品中";
      case "9":
        return "終売";
      default:
        return "ー";
    }
  };
  const getStockingPatternText = () => {
    switch (stockingPatternRef.current) {
      case "1":
        return "メーカー";
      case "2":
        return "卸";
      case "3":
        return "その他";
      default:
        return "ー";
    }
  };
  const getFreeShippingConditionText = () => {
    switch (freeShippingConditionRef.current) {
      case "1":
        return "条件なし";
      case "2":
        return "条件あり";
      case "3":
        return "送料無料";
      default:
        return "ー";
    }
  };

  const getKaHiText = (val: string) => {
    switch (val) {
      case "0":
        return "不可";
      case "1":
        return "可能";
      default:
        return "不可";
    }
  };

  const getWholesalerName = () => {
    return wholesalerNameRef.current === "" ? "ー" : wholesalerNameRef.current;
  };

  useEffect(() => {
    if (!auth.token) {
      return; // auth.tokenが初期値の場合、API実行をスキップ
    }
    setProgressbarFlg(true);
    const fetchData = async () => {
      //console.log("取得開始")
      //注意！！auth.tokenを出力しようとすると、エラーになる
      //console.log(auth.token)
      try {
        const response = await axios.get(API_URL_PRODUCT_DETAIL, {
          params: {
            prodID: prodID,
            timestamp: timestamp,
          },
          headers: {
            Authorization: auth.token,
          },
        });
        console.log(response);
        const result = response.data.result;
        statusRef.current = result["Status"];
        manufacturerRef.current = result["Manufacturer"];
        imageURLRef.current = result["ImageURL"];
        //productPublishFlagRef.current = result["ProductPublishFlag"];
        productNameRef.current = result["ProductName"];
        shopifyURLRef.current = result["ShopifyURL"];
        categoryRef.current = result["Category"];
        janCodeRef.current = result["JanCode"];
        asinRef.current = result["Asin"];
        amazonJPOfferableRef.current = result["AmazonJPOfferable"];
        jpURLRef.current = result["JpURL"];
        jpRankingRef.current = result["JpRanking"];
        jpPriceRef.current = result["JpPrice"];
        rakutenURLRef.current = result["RakutenURL"];
        yahooURLRef.current = result["YahooURL"];
        amazonUSOfferableRef.current = result["AmazonUSOfferable"];
        comURLRef.current = result["ComURL"];
        comRankingRef.current = result["ComRanking"];
        comPriceRef.current = result["ComPrice"];
        purchaseLimitRef.current = result["PurchaseLimit"];
        purchasePriceRef.current = result["PurchasePrice"];
        regularPriceRef.current = result["RegularPrice"];
        actualWeightGramsRef.current = result["ActualWeightGrams"];
        measurementRef.current = result["Measurement"];
        lengthCMRef.current = result["LengthCM"];
        widthCMRef.current = result["WidthCM"];
        heightCMRef.current = result["HeightCM"];
        volumetricWeightGramsRef.current = result["VolumetricWeightGrams"];
        domesticAdmissibilityRef.current =
          result.manufacturerInfo?.domesticAdmissibility;
        overseasAdmissibilityRef.current =
          result.manufacturerInfo?.overseasAdmissibility;
        domesticDropShiipingRef.current =
          result.manufacturerInfo?.domesticDropShiiping;
        overseasDropShiipingRef.current =
          result.manufacturerInfo?.overseasDropShiiping;
        stockingPatternRef.current = result.manufacturerInfo?.stockingPattern;
        wholesalerNameRef.current = result.manufacturerInfo?.wholesalerName;
        freeShippingConditionRef.current =
          result.manufacturerInfo?.freeShippingCondition;
        purchaseOrderPatternRef.current =
          result.manufacturerInfo?.purchaseOrderPattern;
        // yearRef.current = timestamp.substring(0,4)

        //setErrorMessage("");
      } catch (error) {
        console.error(error);
      } finally {
        //console.log("finally");
        setProgressbarFlg(false);
      }
    };

    fetchData();
  }, [auth.token, prodID, timestamp]);

  const cellStyle = {
    padding: "4px", // セル内の余白を調整
    //border: "1px solid #e2e8f0", // セルの境界線を設定
  };

  const theme = useTheme(); // テーマを取得
  const cellStyleBorderColor = {
    padding: "4px", // セル内の余白を調整
    //border: "1px solid #e2e8f0", // セルの境界線を設定
    borderRight: `2px solid ${theme.colors.blue[100]}`,
  };

  const handleAddToCart = async () => {
    setIsAddingToCart(true);

    if (!/^(0|[1-9]\d*)$/.test(quantity)) {
      setAddedMessage("数量は0以上で入力してください");
      setIsAddingToCart(false);
      return;
    }

    const cartData: CartData = {
      productName: productNameRef.current,
      purchasePrice: purchasePriceRef.current,
      imageURL: imageURLRef.current,
      quantity: quantity,
      janCode: janCodeRef.current,
      manufacturer: manufacturerRef.current,
      wholesaler: wholesalerNameRef.current,
      prodID: prodID,
      timestamp: timestamp || "",
    };
    //let cartDataList: object = JSON.parse(localStorage.getItem("cartDataList") || "{}");
    let cartDataList: { [key: string]: CartData } = JSON.parse(
      localStorage.getItem("cartDataList") || "{}"
    );
    // if (cartDataList === null) {
    //   cartDataList = {};
    // }
    cartDataList[prodID] = cartData;
    localStorage.setItem("cartDataList", JSON.stringify(cartDataList));
    //console.log(localStorage);
    setQuantity(quantity);
    setAddedMessage("カートに追加しました");
    setIsAddingToCart(false);
    setSubmitted(true);
    //localStorage.clear();
    window.dispatchEvent(new Event("cartDataUpdated"));
  };

  const handleRemoveFromCart = () => {
    let cartDataList: { [key: string]: CartData } = JSON.parse(
      localStorage.getItem("cartDataList") || "{}"
    );
    //alert("カートから削除ボタン押下");
    //quantityを0に更新
    setQuantity("0");
    if (cartDataList[prodID] !== null) {
      //カートの中からprodIDのデータを削除
      delete cartDataList[prodID];
      // オブジェクトを文字列に変換してlocalStorageに保存
      localStorage.setItem("cartDataList", JSON.stringify(cartDataList));
      //カートから削除しましたとメッセージを表示ï
      setAddedMessage("カートから削除しました");
      window.dispatchEvent(new Event("cartDataUpdated"));
    }
  };

  return (
    <>
      <Navbar />
      {progressbarFlg ? (
        <Container py={{ base: "2", md: "2" }} px={{ base: "0", sm: "0" }}>
          {/* // プログレスアイコンを表示する */}
          <Center height="50vh">
            <Box maxWidth="600px" mx="auto" mt="4" pb="4">
              <CircularProgress isIndeterminate size="100" color="gray.500" />
            </Box>
          </Center>
        </Container>
      ) : (
        // データの読み込みが完了したら表示するコンテンツを記述する
        <div>
          {/* <Text textAlign="right" fontSize="xl">
            為替レート 1ドル {exchangeRate}円 ({timestampStr}時点)
          </Text> */}
          <Container
            pt={{ base: "4", md: "8" }}
            pb={{ base: "12", md: "20" }}
            px={{ base: "0", sm: "0" }}
          >
            <Box
              //as="form"
              //shadow="md"
              borderRadius="md"
              bg={{ base: "white", sm: "bg-surface" }}
              //m="2"
              py={{ base: "0", sm: "2" }}
              px={{ base: "4", sm: "2" }}
            >
              <Box p="2" bg=""></Box>
              <Flex justify="center" align="center">
                <Heading as="h3" size="lg">
                  {productNameRef.current}
                </Heading>
              </Flex>
              <Box p="2" bg=""></Box>
              <Divider borderWidth="1.5px" />
              {/* <Box p="4" bg=""></Box> */}
              {/* 画像 */}
              <Box p="4" bg=""></Box>
              <Flex justify="center" align="center">
                <Box
                  w="200px"
                  h="200px"
                  bg="transparent"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    src={imageURLRef.current || '/m_e_others_501.png'}
                    alt="products"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                </Box>
              </Flex>
              <Box p="2" bg=""></Box>
              <Flex justify="center" align="center">
                <Button
                  onClick={handleCopyClick}
                  bg="blue.500"
                  color="white"
                  //size="md"
                  style={{
                    width: "200px", // 幅をピクセル単位で指定
                    height: "40px", // 高さをピクセル単位で指定
                  }}
                >
                  &emsp;&emsp;{copied ? "Copied" : "Copy"}&emsp;&emsp;
                </Button>
              </Flex>
              <Box p="2" bg=""></Box>
              <Flex justify="center" align="center">
                <Button
                  onClick={handleOpen}
                  bg="blue.500"
                  color="white"
                  _hover={{ bg: "blue.600" }}
                  //size="md"
                  style={{
                    width: "200px", // 幅をピクセル単位で指定
                    height: "40px", // 高さをピクセル単位で指定
                  }}
                >
                  &emsp;&emsp;{submitted ? "追加完了" : "見積追加"}&emsp;&emsp;
                </Button>
              </Flex>
              <Box p="4" bg=""></Box>
              <Flex justify="center" align="center">
                <Table variant="simple" maxWidth="580px">
                  <Tbody>
                    {/* 自社管理ID */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        {/* <Text fontWeight="bold" textAlign="right">
                          自社管理ID:
                        </Text> */}
                        <Flex align="center" justify="flex-end">
                          <Text fontWeight="bold" textAlign="right">
                            自社管理ID
                          </Text>
                          <Tooltip
                            hasArrow
                            label="ヘッダーをコピー"
                            bg="blue.500"
                            aria-label="A tooltip"
                            placement="top"
                            fontSize="md"
                          >
                            {headerCopied ? (
                              <Box style={{ marginTop: "2px" }}>
                                <CheckIcon boxSize={4} color="teal.400" m={1} />
                              </Box>
                            ) : (
                              <Button
                                onClick={handleHeaderCopyClick}
                                size="xs"
                                style={{ marginTop: "2px", background: "none" }}
                              >
                                <InfoIcon
                                  boxSize={4}
                                  color="teal.400"
                                  m={0}
                                />
                              </Button>
                            )}
                          </Tooltip>
                          <Text fontWeight="bold" textAlign="right">
                            :
                          </Text>
                        </Flex>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {prodID}
                      </Td>
                    </Tr>
                    {/* ステータス */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          ステータス:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {getStatusText()}
                      </Td>
                    </Tr>

                    {/* カテゴリ */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          カテゴリ:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {categoryRef.current}
                      </Td>
                    </Tr>
                    {/* JANコード */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          JANコード:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {janCodeRef.current}
                      </Td>
                    </Tr>
                    {/* 購入制限数 単位 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          購入制限数 単位:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {purchaseLimitRef.current}個以下
                      </Td>
                    </Tr>
                    {/* 仕入概算単価 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          仕入概算単価(税込):
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(purchasePriceRef.current).toLocaleString()}円
                      </Td>
                    </Tr>
                    {/* 定価 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          定価:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(regularPriceRef.current).toLocaleString()}円
                      </Td>
                    </Tr>
                    {/* 実重量(g) */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          実重量:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(actualWeightGramsRef.current).toLocaleString()}g
                      </Td>
                    </Tr>
                    {/* 縦(cm) */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          縦:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(lengthCMRef.current).toLocaleString()}cm
                      </Td>
                    </Tr>
                    {/* 横(cm) */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          横:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(widthCMRef.current).toLocaleString()}cm
                      </Td>
                    </Tr>
                    {/* 高さ(cm) */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          高さ:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(heightCMRef.current).toLocaleString()}cm
                      </Td>
                    </Tr>
                    {/* 容積重量(g) */}
                    <Tr>
                      <Td
                        border="none"
                        style={cellStyle}
                        className="header-cell"
                      >
                        <Flex align="center" justify="flex-end">
                          <Text fontWeight="bold" textAlign="right">
                            容積重量
                          </Text>
                          <Tooltip
                            hasArrow
                            label="縦×横×高さ/5000"
                            bg="blue.500"
                            aria-label="A tooltip"
                            placement="top"
                            fontSize="md"
                          >
                            <QuestionIcon boxSize={4} color="teal.400" m={2} />
                          </Tooltip>
                          <Text fontWeight="bold" textAlign="right">
                            :
                          </Text>
                        </Flex>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(
                          volumetricWeightGramsRef.current
                        ).toLocaleString()}
                      </Td>
                    </Tr>
                    {/* 最終更新日時 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          最終更新日時:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {parseInt(yearRef.current, 10)}年
                        {parseInt(monthRef.current, 10)}月
                        {parseInt(dayRef.current, 10)}日
                      </Td>
                    </Tr>
                    {/* メーカー情報 */}
                    <Tr background="blue.100">
                      <Td border="none" style={cellStyleBorderColor}>
                        <Text fontWeight="bold" textAlign="right">
                          メーカー情報:
                        </Text>
                      </Td>
                      <Td border="none"></Td>
                    </Tr>
                    {/* メーカー名 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          メーカー名:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {manufacturerRef.current}
                      </Td>
                    </Tr>
                    {/* 国内EC2販売 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          国内EC2販売:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {/* {domesticAdmissibilityRef.current == "1" ? "可能" : "不可"} */}
                        {getKaHiText(domesticAdmissibilityRef.current)}
                      </Td>
                    </Tr>
                    {/* 海外EC2販売 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          海外EC2販売:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {/* {overseasAdmissibilityRef.current == "1" ? "可能" : "不可"} */}
                        {getKaHiText(overseasAdmissibilityRef.current)}
                      </Td>
                    </Tr>
                    {/* 国内ﾄﾞﾛｯﾌﾟｼｯﾌﾟ */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          国内ﾄﾞﾛｯﾌﾟｼｯﾌﾟ:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {/* {domesticDropShiipingRef.current == "1" ? "可能" : "不可"} */}
                        {getKaHiText(domesticDropShiipingRef.current)}
                      </Td>
                    </Tr>
                    {/* 海外ﾄﾞﾛｯﾌﾟｼｯﾌﾟ */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          海外ﾄﾞﾛｯﾌﾟｼｯﾌﾟ:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {/* {overseasDropShiipingRef.current == "1" ? "可能" : "不可"} */}
                        {getKaHiText(overseasDropShiipingRef.current)}
                      </Td>
                    </Tr>
                    {/* 仕入れ形態 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          仕入れ形態:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {getStockingPatternText()}
                      </Td>
                    </Tr>
                    {/* 卸業者名 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          卸業者名:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {getWholesalerName()}
                      </Td>
                    </Tr>
                    {/* 送料無料条件 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          送料無料条件:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {getFreeShippingConditionText()}
                      </Td>
                    </Tr>
                    {/* Amazon.jp情報 */}
                    <Tr background="blue.100">
                      <Td border="none" style={cellStyleBorderColor}>
                        <Text fontWeight="bold" textAlign="right">
                          Amazon.jp情報:
                        </Text>
                      </Td>
                      <Td border="none"></Td>
                    </Tr>
                    {/* ASIN */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          ASIN:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {asinRef.current}
                      </Td>
                    </Tr>
                    {/* URL */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          URL:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        <Link href={jpURLRef.current} isExternal>
                          <Text fontSize={14} textDecoration="underline">{jpURLRef.current}</Text>
                        </Link>
                      </Td>
                    </Tr>
                    {/* 出品可否 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          出品可否:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {/* {amazonJPOfferableRef.current == "1" ? "可能" : "不可"} */}
                        {getKaHiText(amazonJPOfferableRef.current)}
                      </Td>
                    </Tr>
                    {/* ランキング */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          ランキング:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(jpRankingRef.current).toLocaleString()}位
                      </Td>
                    </Tr>
                    {/* 単価 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          単価:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(jpPriceRef.current).toLocaleString()}円
                      </Td>
                    </Tr>
                    {/* Amazon.com情報 */}
                    <Tr background="blue.100">
                      <Td border="none" style={cellStyleBorderColor}>
                        <Text fontWeight="bold" textAlign="right">
                          Amazon.com情報:
                        </Text>
                      </Td>
                      <Td border="none"></Td>
                    </Tr>
                    {/* URL */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          URL:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        <Link href={comURLRef.current} isExternal>
                          <Text fontSize={14} textDecoration="underline">{comURLRef.current}</Text>
                        </Link>
                      </Td>
                    </Tr>
                    {/* 出品可否 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          出品可否:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {/* {amazonUSOfferableRef.current == "1" ? "可能" : "不可"} */}
                        {getKaHiText(amazonUSOfferableRef.current)}
                      </Td>
                    </Tr>
                    {/* ランキング */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          ランキング:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(comRankingRef.current).toLocaleString()}位
                      </Td>
                    </Tr>
                    {/* 単価 */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          単価:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        {Number(comPriceRef.current).toLocaleString()}$
                      </Td>
                    </Tr>
                    {/* shopify情報 */}
                    <Tr background="blue.100">
                      <Td border="none" style={cellStyleBorderColor}>
                        <Text fontWeight="bold" textAlign="right">
                          shopify情報:
                        </Text>
                      </Td>
                      <Td border="none"></Td>
                    </Tr>
                    {/* URL */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          URL:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        <Link href={shopifyURLRef.current} isExternal>
                          <Text fontSize={14} textDecoration="underline">{shopifyURLRef.current}</Text>
                        </Link>
                      </Td>
                    </Tr>
                    {/* 楽天情報 */}
                    <Tr background="blue.100">
                      <Td border="none" style={cellStyleBorderColor}>
                        <Text fontWeight="bold" textAlign="right">
                          楽天情報:
                        </Text>
                      </Td>
                      <Td border="none"></Td>
                    </Tr>
                    {/* URL */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          URL:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        <Link href={rakutenURLRef.current} isExternal>
                          <Text fontSize={14} textDecoration="underline">{rakutenURLRef.current}</Text>
                        </Link>
                      </Td>
                    </Tr>
                    {/* Yahoo!情報 */}
                    <Tr background="blue.100">
                      <Td border="none" style={cellStyleBorderColor}>
                        <Text fontWeight="bold" textAlign="right">
                          Yahoo!情報:
                        </Text>
                      </Td>
                      <Td border="none"></Td>
                    </Tr>
                    {/* URL */}
                    <Tr>
                      <Td border="none" style={cellStyle}>
                        <Text fontWeight="bold" textAlign="right">
                          URL:
                        </Text>
                      </Td>
                      <Td border="none" style={cellStyle}>
                        <Link href={yahooURLRef.current} isExternal>
                          <Text fontSize={14} textDecoration="underline">{yahooURLRef.current}</Text>
                        </Link>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Flex>
            </Box>
          </Container>
          <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>カートに追加</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>数量</FormLabel>
                  {/* <Input
                    placeholder="数量を入力"
                    name="quantity"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  /> */}
                  <NumberInput
                    defaultValue={0}
                    min={0}
                    value={quantity}
                    onChange={(value) => setQuantity(value)}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Box style={{ height: "5px" }}></Box>
                  {shopifyURLRef.current && (
                    <Link
                      href={shopifyURLRef.current}
                      isExternal
                      style={{
                        float: "right",
                        textDecoration: "underline",
                        fontSize: "smaller",
                        color: "blue",
                      }}
                    >
                      Shopifyで購入する
                    </Link>
                  )}
                </FormControl>
                {/**カートへの追加が完了したら、「追加が完了しましたのメッセージを表示」 */}
                <Text color="red">{addedMessage}</Text>
              </ModalBody>

              <ModalFooter justifyContent="space-between">
                {/* カートから削除ボタンを左寄せで配置 */}
                <Button
                  colorScheme="red"
                  onClick={handleRemoveFromCart}
                  // isDisabled={localStorage.getItem(prodID) !== null}
                >
                  削除
                </Button>
                <Spacer />
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={handleAddToCart}
                  isLoading={isAddingToCart}
                  isDisabled={isAddingToCart || Number(quantity) < 1}
                >
                  カートに追加
                </Button>
                <Button onClick={handleClose}>閉じる</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      )}
    </>
  );
});
export default ProductDetail;
