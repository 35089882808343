import { memo } from "react";
import { Box, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  ICON_URL_AMAZON_JP,
  ICON_URL_AMAZON_US,
  ICON_URL_SHOPIFY,
  ICON_URL_RAKUTEN,
  ICON_URL_YAHOO,
} from "../../constants/constants";


/**
 * searchResult:Array
 * searchResultの各要素：6つの要素の配列
 * result[0]：商品ID
 * result[1]：商品名
 * result[2]：仕入れ価格
 * result[3]：タイムスタンプ
 * result[4]：画像URL
 * result[5]：AmazonJPURL
 * result[6]：AmazoUSURL
 * result[7]：shopifyURL
 * result[8]：RakutenURL
 * result[9]：yahooURL
 *  (商品詳細ファイルは「$(商品ID)-$(タイムスタンプ).json」の形で保持している。)
 */

interface SearchResultRowProps {
  result: string[];
  lastElementRef: any | null;
}
const SearchResultRow: React.FC<SearchResultRowProps> = memo(
  ({ result, lastElementRef = null }) => {
    // const cssImg: React.CSSProperties = {
    //   maxWidth: "100px",
    //   marginRight: "2px",
    //   width: "80px",
    //   height: "80px",
    //   border: "1px solid #ddd",
    //   objectFit: "fill",
    // };

    return (
      <tr ref={lastElementRef}>
        {/* 商品画像 */}
        <th scope="row">
          <Link
            to={`/product?prodID=${result[0]}&timestamp=${result[3]}`}
            target="_blank"
          >
            <Box
              w="100px"
              h="100px"
              bg="transparent"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={result[4] || '/m_e_others_501.png'}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                alt={`商品画像: ${result[1]}`}
                loading="lazy"
              />
            </Box>
          </Link>
        </th>
        {/* 商品ID */}
        <td>
          <Link
            to={`/product?prodID=${result[0]}&timestamp=${result[3]}`}
            target="_blank"
            className="blue-link"
          >
            {result[0]}
          </Link>
        </td>
        {/* 商品名 */}
        <td align="left">{result[1]}</td>
        {/* 仕入れ価格 */}
        <td align="right">{Number(result[2]).toLocaleString()}円</td>
        {/* 商品URL */}
        <td align="left">
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {result[5] && (
              <a href={result[5]} target="_blank" rel="noopener noreferrer">
                <Image
                  boxSize="25px"
                  src={ICON_URL_AMAZON_JP}
                  alt="AmazonJP"
                  mb={2}
                  mr={2}
                />
              </a>
            )}
            {result[6] && (
              <a href={result[6]} target="_blank" rel="noopener noreferrer">
                <Image
                  boxSize="25px"
                  src={ICON_URL_AMAZON_US}
                  alt="AmazonUS"
                  mb={2}
                  mr={2}
                />
              </a>
            )}
            {result[7] && (
              <a href={result[7]} target="_blank" rel="noopener noreferrer">
                <Image
                  boxSize="25px"
                  src={ICON_URL_SHOPIFY}
                  alt="Shopify"
                  mb={2}
                  mr={2}
                />
              </a>
            )}
            {result[8] && (
              <a href={result[8]} target="_blank" rel="noopener noreferrer">
                <Image
                  boxSize="25px"
                  src={ICON_URL_RAKUTEN}
                  alt="Rakuten"
                  mb={2}
                  mr={2}
                />
              </a>
            )}
            {result[9] && (
              <a href={result[9]} target="_blank" rel="noopener noreferrer">
                <Image
                  boxSize="25px"
                  src={ICON_URL_YAHOO}
                  alt="Yahoo"
                  mb={2}
                  mr={2}
                />
              </a>
            )}
          </Box>
        </td>
      </tr>
    );
  }
);

export default SearchResultRow;
