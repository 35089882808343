import React, { useState, useEffect } from "react";
import Navbar from "./molecules/Navbar";
import {
  Box,
  Text,
  Container,
  Button,
  Divider,
  Input,
  FormControl,
  FormLabel,
  Flex,
  CircularProgress,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CartData } from "../interface/CartData";
import { API_URL_QUOTATION } from "../constants/constants";
import { useAuth } from "../hooks/use-auth";
import hashFunctionSHA256 from "../api/hash";
import { Link } from "react-router-dom";

const InputInfoConfirm: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [progressbarFlg, setProgressbarFlg] = useState<boolean>(false);
  const toast = useToast();

  const handleNext = async () => {
    //サーバーからユーザーの現時点の認証情報を取得
    await auth.currentAuthenticatedUser();
    //認証切れの場合はログイン画面へ遷移
    if (!auth.isAuthenticated) {
      toast({
        title: "タイムアウトしました。",
        position: "top",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      navigate({ pathname: "/" });
      return ;
    }
    setProgressbarFlg(true);

    //カート内の変更を検知するためのHashを更新
    const cartDataListHashBefore = sessionStorage.getItem("cartDataListHash");
    //console.log(cartDataListHashBefore);
    const cartDataListStr = localStorage.getItem("cartDataList") || "[]";
    const cartDataListHashAfter = await hashFunctionSHA256(cartDataListStr);
    if (cartDataListHashBefore !== cartDataListHashAfter) {
      //console.log("カート内の変更を検知しました");
      toast({
        title: "カートが変更されました。",
        position: "top",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      //ListingCartへ遷移
      navigate({ pathname: "/cart" });
      return;
    }

    const userName = sessionStorage.getItem("userName") || "";
    const phone1 = sessionStorage.getItem("phone1") || "";
    const phone2 = sessionStorage.getItem("phone2") || "";
    const phone3 = sessionStorage.getItem("phone3") || "";
    const email = sessionStorage.getItem("email") || "";
    const zip1 = sessionStorage.getItem("zip1") || "";
    const zip2 = sessionStorage.getItem("zip2") || "";
    const address1 = sessionStorage.getItem("address1") || "";
    const address2 = sessionStorage.getItem("address2") || "";
    const address3 = sessionStorage.getItem("address3") || "";

    //cartDataListからキー(商品ID)とquantityを抽出する
    const newCartDataList = Object.entries(cartDataList).map(
      ([key, { quantity, janCode, manufacturer, wholesaler }]) => ({
        key: key, // 商品ID
        quantity: quantity, // 数量
        janCode: janCode,
        manufacturer: manufacturer,
        wholesaler: wholesaler,
      })
    );

    //AWS APIGatewayにPOSTリクエストを送信
    const data = {
      userName: userName, // nullの場合、空文字列を使用
      phone: phone1 + "-" + phone2 + "-" + phone3, // nullの場合、空文字列を使用
      email: email, // nullの場合、空文字列を使用
      zip: zip1 + "-" + zip2, // nullの場合、空文字列を使用
      address: address1 + address2 + address3,
      cartDataList: newCartDataList,
    };

    //console.log(data);
    try {
      const response = await axios.post(API_URL_QUOTATION, data, {
        headers: {
          Authorization: auth.token,
        },
      });
      console.log(response);
      const errorMessage = response.data.error;
      if (errorMessage !== "") {
        setErrorMessage(errorMessage);
        setProgressbarFlg(false);
      }
    } catch (error) {
      //console.log("エラー");
      console.log(error);
      //setErrorMessage("エラーが発生しました。");
      setProgressbarFlg(false);
    }
    //処理結果正常の場合は、LocalStorageの商品データを削除する
    localStorage.removeItem("cartDataList");
    //sessionのデータを全て削除
    sessionStorage.clear();

    setErrorMessage("");
    // ここに次のアクションを定義
    navigate({ pathname: "/quotationcomplete" });
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [cartDataList, setCartDataList] = useState<CartData[]>([]);

  useEffect(() => {
    const fetchCartData = () => {
      try {
        const cartData = localStorage.getItem("cartDataList");
        if (cartData) {
          setCartDataList(JSON.parse(cartData));
          console.log(cartData);
        }
      } catch (error) {
        console.error("カートデータの取得に失敗しました", error);
      }
    };

    fetchCartData();
  }, []);

  return (
    <>
      <Navbar />
      <Container
        pt={{ base: "4", md: "8" }}
        pb={{ base: "12", md: "20" }}
        px={{ base: "0", md: "0" }}
        maxW={{ base: "80%", md: "500px" }}
      >
        <Box
          //as="form"
          shadow="md"
          borderRadius="md"
          bg={{ base: "white", md: "bg-surface" }}
          //m="2"
          py={{ base: "0", md: "2" }}
          px={{ base: "4", md: "2" }}
        >
          <Text
            fontSize="14px"
            pt="10px"
            mb="10px"
            style={{ textAlign: "center" }}
          >
            商品情報の確認 ＞ ユーザ情報の確認 ＞
            <span style={{ color: "red" }}>入力情報の確認</span>＞ 見積依頼完了
          </Text>
          {/* <Text align="center">商品情報</Text> */}
          <Box h="5px"></Box>
          {/* <Divider borderColor="darkgray"></Divider> */}
          <Divider borderColor="darkgray" borderWidth="1.5px"></Divider>
          <Box h="10px"></Box>
          {/* カート情報の表示 */}
          <Text fontSize="1.2em" fontWeight="bold">
            カート情報
          </Text>
          <div>
            {Object.entries(cartDataList).map(([key, value]) => (
              <div
                key={key}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #cdcccc",
                  margin: "2px 0",
                }}
              >
                <Box
                  w="100px"
                  h="100px"
                  bg="transparent"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="4"
                  mb="2"
                >
                  <img
                    src={value.imageURL}
                    alt={value.productName}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </Box>
                <div style={{ textAlign: "left" }}>
                  <span>{value.productName}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "red" }}>
                    {value.purchasePrice.toLocaleString()}円
                  </span>
                  <span style={{ color: "red" }}>{value.quantity}個</span>
                </div>
              </div>
            ))}
            {/* 合計の金額を表示 */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                見積合計:&emsp;{" "}
              </span>
              <span
                style={{
                  color: "red",
                  fontSize: "1.2em",
                  fontWeight: "bold",
                }}
              >
                {Object.values(cartDataList)
                  .reduce(
                    (total: number, item: CartData) =>
                      total +
                      Number(item.purchasePrice) * Number(item.quantity),
                    0 as number
                  )
                  .toLocaleString()}
                円
              </span>
            </div>
            {/* <Divider borderColor="darkgray"></Divider> */}

            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
            </div> */}
          </div>

          {/* 線を少し太く */}
          <Divider borderColor="darkgray" borderWidth="1.5px"></Divider>

          <Box h="10px"></Box>
          <Text fontSize="1.2em" fontWeight="bold">
            お客様情報
          </Text>
          <Box h="20px"></Box>
          {/* お名前 */}
          <FormControl isRequired>
            <FormLabel htmlFor="name">お名前</FormLabel>
            <Input
              type="text"
              id="name"
              name="name"
              size="sm"
              value={sessionStorage.getItem("userName") || ""}
              isReadOnly
              border="none"
              _focus={{ border: "none" }}
            />
          </FormControl>
          <Box h="20px"></Box>
          {/* 電話番号 */}
          <FormControl isRequired>
            <FormLabel htmlFor="phone">電話番号</FormLabel>

            <Flex justifyContent="space-between">
              <Input
                type="tel"
                //placeholder="000"
                id="phone1"
                name="phone1"
                size="sm"
                mr={2}
                value={sessionStorage.getItem("phone1") || ""}
                isReadOnly
                border="none"
                _focus={{ border: "none" }}
              />
              ー
              <Input
                type="tel"
                //placeholder="000"
                maxLength={3}
                size="sm"
                ml={2}
                mr={2} // 右のマージン
                value={sessionStorage.getItem("phone2") || ""}
                isReadOnly
                border="none"
                _focus={{ border: "none" }}
              />
              ー
              <Input
                type="tel"
                //placeholder="0000"
                maxLength={4}
                size="sm"
                ml={2} // 左のマージン
                value={sessionStorage.getItem("phone3") || ""}
                isReadOnly
                border="none"
                _focus={{ border: "none" }}
              />
              {/* 項目間のスペースが空いてしまっているので詰めたいです */}
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            </Flex>
          </FormControl>
          <Box h="20px"></Box>
          {/* メールアドレス */}
          <FormControl isRequired>
            <FormLabel htmlFor="email">メールアドレス</FormLabel>
            <Input
              type="email"
              id="email"
              name="email"
              size="sm"
              value={sessionStorage.getItem("email") || ""}
              isReadOnly
              border="none"
              _focus={{ border: "none" }}
            />
          </FormControl>
          <Box h="20px"></Box>
          {/* (仕入時)送付先住所(郵便番号、住所1、住所2) */}
          <FormControl isRequired>
            <FormLabel htmlFor="zip">郵便番号</FormLabel>
            <Flex justifyContent="space-between">
              <Input
                //placeholder="123"
                maxLength={3}
                size="sm"
                mr={2} // 右のマージン
                value={sessionStorage.getItem("zip1") || ""}
                isReadOnly
                border="none"
                _focus={{ border: "none" }}
              />
              ー
              <Input
                //placeholder="4567"
                maxLength={4}
                size="sm"
                ml={2} // 左のマージン
                value={sessionStorage.getItem("zip2") || ""}
                isReadOnly
                border="none"
                _focus={{ border: "none" }}
              />
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            </Flex>
          </FormControl>
          <Box h="20px"></Box>
          <FormControl isRequired>
            <FormLabel htmlFor="address1">都道府県</FormLabel>
            <Input
              id="address1"
              name="address1"
              size="sm"
              //placeholder="都道府県を選択してください"
              value={sessionStorage.getItem("address1") || ""}
              isReadOnly
              border="none"
              _focus={{ border: "none" }}
            />
          </FormControl>
          <Box h="20px"></Box>
          <FormControl isRequired>
            <FormLabel htmlFor="address2">住所</FormLabel>
            <Input
              type="text"
              id="address2"
              name="address2"
              //placeholder="市区町村"
              size="sm"
              value={sessionStorage.getItem("address2") || ""}
              isReadOnly
              border="none"
              _focus={{ border: "none" }}
            />
          </FormControl>
          <Box h="20px"></Box>
          <FormControl isRequired>
            <FormLabel htmlFor="address3">建物名・部屋番号</FormLabel>
            <Input
              type="text"
              id="address3"
              name="address3"
              //placeholder="建物名"
              size="sm"
              value={sessionStorage.getItem("address3") || ""}
              isReadOnly
              border="none"
              _focus={{ border: "none" }}
            />
          </FormControl>
          <Box h="20px"></Box>
          {/* 次へ　ボタンを右寄せで表示 */}
          <Divider borderColor="darkgray" borderWidth="1.5px"></Divider>
          <Box h="10px"></Box>
          <Text color="red" style={{ textAlign: "right" }}>
            {errorMessage}
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link
              to="/userinfoconfirm"
              style={{
                color: "#3182CE",
                padding: "12px",
                fontSize: "sm",
                marginTop: "2",
                marginBottom: "2",
                width: "40%",
                display: "inline-block",
                textAlign: "center"
              }}
              onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")} // カーソルが当たったら下線を引く
              onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")} // カーソルが離れたら下線を消す

            >
              ユーザ情報を確認する
            </Link>
            <Button
              isDisabled={progressbarFlg}
              bg="blue.500"
              color="white"
              size="sm"
              mt="2"
              mb="2"
              _hover={{ bg: "blue.600" }}
              leftIcon={
                progressbarFlg ? (
                  <CircularProgress
                    isIndeterminate
                    size="30"
                    color="blue.500"
                  />
                ) : (
                  <CheckIcon />
                )
              }
              onClick={handleNext}
            >
              見積依頼の完了
            </Button>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default InputInfoConfirm;
