import { memo, FC, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../components/Login";
import NotFound from "../components/NotFound";
import Top from "../components/Top";
import { useAuth } from "../hooks/use-auth";
import SearchProduct from "../components/SearchProduct";
import ProductDetail from "../components/ProductDetail";
import ForcePasswordChange from "../components/ForcePasswordChange";
import CurrentPasswordChange from "../components/CurrentPasswordChange";
import ForgotPassword1SendCode from "../components/ForgotPassword1SendCode";
import ForgotPassword2ChangePassword from "../components/ForgotPassword2ChangePassword";
import ListingCart from "../components/ListingCart";
import Protected from "../router/Protected";
import { CircularProgress, Center } from "@chakra-ui/react";
import UserInfoConfirm from "../components/UserInfoConfirm";
import QuotationComplete from "../components/QuotationComplete";
import InputInfoConfirm from "../components/InputInfoConfirm";

export const Router: FC = memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    const CheckAuthStatus = async () => {
      //setIsAuthenticated(auth.isAuthenticated);
      setIsLoading(false);
    };

    CheckAuthStatus();
  }, []);

  return (
    <>
      <div>
        {isLoading ? (
          <Center h="100vh">
            {" "}
            {/* 画面の垂直方向に中央揃え */}
            <CircularProgress isIndeterminate size="30" color="blue.500" />
          </Center>
        ) : (
          <Routes>
            <Route
              path="/top"
              element={
                auth.isAuthenticated ? (
                  <Protected isSignedIn={true}>
                    <Top />
                  </Protected>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route path="/" element={<Login />} />
            <Route
              path="/forgotPassword1SendCode"
              element={<ForgotPassword1SendCode />}
            />
            <Route
              path="/forgotPassword2ChangePassword"
              element={<ForgotPassword2ChangePassword />}
            />
            <Route
              path="/searchProduct"
              element={
                auth.isAuthenticated ? (
                  <Protected isSignedIn={true}>
                    <SearchProduct />
                  </Protected>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/cart"
              element={
                auth.isAuthenticated ? (
                  <Protected isSignedIn={true}>
                    <ListingCart />
                  </Protected>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/userinfoconfirm"
              element={
                auth.isAuthenticated ? (
                  <Protected isSignedIn={true}>
                    <UserInfoConfirm />
                  </Protected>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/inputinfoconfirm"
              element={
                auth.isAuthenticated ? (
                  <Protected isSignedIn={true}>
                    <InputInfoConfirm />
                  </Protected>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/quotationcomplete"
              element={
                auth.isAuthenticated ? (
                  <Protected isSignedIn={true}>
                    <QuotationComplete />
                  </Protected>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/product"
              element={
                auth.isAuthenticated ? (
                  <Protected isSignedIn={true}>
                    <ProductDetail />
                  </Protected>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/currentPasswordChange"
              element={
                auth.isAuthenticated ? (
                  <Protected isSignedIn={true}>
                    <CurrentPasswordChange />
                  </Protected>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/forcePasswordChange"
              element={<ForcePasswordChange />}
            />
            {/* <Route path="/product" element={<ProductDetail />} /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </div>
    </>
  );
});

export default Router;
