import { Heading, Text } from "@chakra-ui/react";
import React from "react";
// import { useAuth } from "../hooks/use-auth";
// import { useNavigate } from "react-router-dom";
import Navbar from "../components/molecules/Navbar";

const Top = () => {
  return (
    <>
      <Navbar />
      <Heading fontSize="30px">Topページ</Heading>
      <br />

      <br />
      <br />
      <Text fontSize="30px" align="center">
        現在お知らせ情報はありません。
      </Text>
    </>
  );
};

export default Top;
